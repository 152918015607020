import React, { useEffect, useRef, useState } from "react";

import {
  orangeBgImage,
  orangeBgImageNoPeople,
  prevBtnImage,
} from "../Assets/images";
import ChooseLoginOrRegister from "../Credentials/choose-login-or-register";
import Register from "../Credentials/register";
import WeSendYouSmsPopup from "../Popups/we-send-you-sms-popup";
import CodeNotRightPopup from "../Popups/code-not-right-popup";
import ApproveDetails from "../Credentials/approve-details";
import Login from "../Credentials/login";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";

const currWishlistId = Number(localStorage.getItem("currWishlistId"));

function Credentials({
  transliterationMap,
  isLoggedInHandler,
  updatePopupState,
  currUserObject,
  setCurrUserObject,
  isRedirectPresent,
  setNotLoggedIn,
  setDiRegister,
  language,
  languageToggle,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const hasNewWishlistPath = location.pathname.includes(
    "credentials-new-wishlist",
  );
  
  const hasReminderPath = location.pathname.includes(
    "reminder",
  );
  console.log("location_state", location.state);
  const params = location?.state ? location.state : {};
  const currWishlistId = params?.currWishlistId;

  const { wishlistId } = useParams();
  console.log("");

  const [stepNumber, setStepNumber] = useState(2);
  const [isOrange, setOrange] = useState(true);
  const [isGreenMobile, setGreenMobile] = useState(true);
  const [isTopNavButtons, setTopNavButtons] = useState(false);
  const [isLoginOrRegister, setLoginOrRegister] = useState({
    choose: true,
    register: false,
    login: false,
  });
  const [isForm, setForm] = useState(true);

  const [isVerifyNumberPopup, setVerifyNumberPopup] = useState(false);
  const [isCodeNotRightPopup, setCodeNotRightPopup] = useState(false);
  const [codeNotRightMessage, setCodeNotRightMessage] = useState("");

  const [isLoginWithPage, setLoginWithPage] = useState({
    loginWithPhone: false,
    loginWithSms: false,
  });

  const [mainBgImage, setMainBgImage] = useState({
    main: orangeBgImage,
    noPeople: orangeBgImageNoPeople,
  });

  const [registerStep, setRegisterStep] = useState({
    userDetails: false,
    verifyPhone: false,
    acceptTerms: false,
    afterRegister: false,
  });

  const [loginStep, setLoginStep] = useState({
    enterPhone: false,
    loginWithSms: false,
  });

  const [progress, setProgress] = useState(0);

  const updateRegisterStep = (stateToUpdate) => {
    setRegisterStep((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };
  const updateLoginStep = (stateToUpdate) => {
    setLoginStep((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };

  const updateLoginOrRegister = (stateToUpdate) => {
    setLoginOrRegister((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };
  const updateStepNumber = (number) => {
    setStepNumber(number);
  };
  const updateColorMode = (isOrange) => {
   // setOrange(isOrange);
  };
  const updateGreenMobile = (isGreen) => {
    setGreenMobile(isGreen);
  };
  const updateTopNavButtons = (isTopNav) => {
    setTopNavButtons(isTopNav);
  };

  const updateMainBgImage = (image, imageNoPeople) => {
  //  setMainBgImage({
   //   main: image,
   //   noPeople: imageNoPeople,
   // });
  };

  const updateIsForm = (value) => {
    setForm(value);
  };

  const updateVerifyNumberPopup = (value) => {
    setVerifyNumberPopup(value);
  };
  const updateCodeNotRightPopup = (value) => {
    setCodeNotRightPopup(value);
  };
  const updateCodeNotRightMessage = (value) => {
    setCodeNotRightMessage(value);
  };
  const updateLoginWith = (stateToUpdate) => {
    setLoginWithPage((prevState) => ({
      ...prevState,
      ...stateToUpdate,
    }));
  };

  const updateProgress = (value) => {
    setProgress(value);
  };

  const resetOtpInputs = () => {
    const inputs = document.querySelectorAll(
      ".form-credentials-section__code input",
    );
    if (!inputs) return;
    inputs.forEach((input) => (input.value = ""));
  };
  useEffect(() => {
    localStorage.removeItem("currUserObject");
  }, []);
  const addUserIdToCreatedWishlist = async (userId, wishlistId) => {
    console.log("addUserIdToCreatedWishlist", userId, wishlistId);
    axios
      .post(`${API_DOMAIN}/api/ProductCategory/UpdateWishlist`, {
        userId,
        wishlistId,
      })
      .then((response) => {
        console.log("addUserIdToCreatedWishlist", response.data);
        if (response.data.result) {
          setLoginOrRegister({ approve: true }); // after ivent created
          redirectAfterDetailsApproved();
          window.scrollTo(0, 0);
        } else {
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        alert("Error, please try again");
      });
  };

  //////////((((()))))
  function redirectAfterDetailsApproved() {
    
    const pendingPresent = JSON.parse(localStorage.getItem('pendingPresent'));
    const pendingAction = JSON.parse(localStorage.getItem('redirectAfterLogin'));

    
    debugger;
   if (hasNewWishlistPath) {
      navigate(`/create-event-second-step/${wishlistId}`);
    }
    if(hasReminderPath){
   //   debugger;
      alert("נשלח לך תזכורת חודש לפני האירוע");
      navigate('/');

 }else if (pendingAction && pendingAction.action === 'send-money') {
  alert("התחברת בהצלחה, מעבירים אותך להמשך קנית מתנה")
}
 else if  (pendingPresent) {
  alert("נרשמת בהצלחה, מעבירים אותך להמשך קנית מתנה")
  navigate(`/`);
} 

  }
  

  function getOTPLimitObj() {
    const otpTriesLimit = 4;
    let OTPobj =
      localStorage.getItem("OTPobj") != undefined &&
      localStorage.getItem("OTPobj") != ""
        ? JSON.parse(localStorage.getItem("OTPobj"))
        : null;
    console.log("OTPobj", OTPobj);
    const now = new Date();
    const timeInSeconds = Math.floor(now.getTime() / 1000);
    if (OTPobj == null) {
      // this is the first time then

      OTPobj = {
        attemptsLeft: otpTriesLimit - 1,
        nextAttempt: timeInSeconds + 30,
        isLimited: false,
        waitTime: 0,
      };
      localStorage.setItem("OTPobj", JSON.stringify(OTPobj));
      console.log("OTPobj1", OTPobj);
      return OTPobj;
    } else {
      if (OTPobj.attemptsLeft == 0) {
        if (timeInSeconds > OTPobj.nextAttempt) {
          OTPobj.attemptsLeft = otpTriesLimit - 1;
          OTPobj.nextAttempt = timeInSeconds + 30;
          OTPobj.isLimited = false;
          OTPobj.waitTime = 0;
          localStorage.setItem("OTPobj", JSON.stringify(OTPobj));
          console.log("OTPobj2", OTPobj);
          return OTPobj;
        } else {
          //OTPobj.nextAttempt = timeInSeconds + 30,
          OTPobj.isLimited = true;
          OTPobj.waitTime = OTPobj.nextAttempt - timeInSeconds;
          localStorage.setItem("OTPobj", JSON.stringify(OTPobj));
          console.log("OTPobj3", OTPobj);
          return OTPobj;
        }
      }
      if (OTPobj.attemptsLeft <= (otpTriesLimit - 1) && OTPobj.attemptsLeft > 0) {
        if (timeInSeconds > OTPobj.nextAttempt) {
          OTPobj.attemptsLeft -= 1;
          OTPobj.nextAttempt =
            OTPobj.attemptsLeft == 0 ? timeInSeconds + 120 : timeInSeconds + 30;
          OTPobj.isLimited = false;
          OTPobj.waitTime = 0;
          localStorage.setItem("OTPobj", JSON.stringify(OTPobj));
          console.log("OTPobj4", OTPobj);
          return OTPobj;
        } else {
          //OTPobj.nextAttempt = timeInSeconds + 30,
          OTPobj.isLimited = true;
          OTPobj.waitTime = OTPobj.nextAttempt - timeInSeconds;
          localStorage.setItem("OTPobj", JSON.stringify(OTPobj));
          console.log("OTPobj5", OTPobj);
          return OTPobj;
        }
      }
    }
  }

  function doSuccessfullyLoggedinLogic(response) {

    isLoggedInHandler(true);
    console.log("user data", response.data);
    setCurrUserObject(response.data);
    localStorage.setItem("currUserObject", JSON.stringify(response.data));
    console.log("isRedirectPresent", isRedirectPresent);
    /* if (setUserNotFirstTime) {
      setUserNotFirstTime(true)
    } */

    /* if (updateDidRegistered) {
      updateDidRegistered(false)
    } */
 
      const pendingPresent = JSON.parse(localStorage.getItem('pendingPresent'));

      const pendingAction = JSON.parse(localStorage.getItem('redirectAfterLogin'));
debugger;
 
    if (hasNewWishlistPath) {
      addUserIdToCreatedWishlist(response.data.user.id, wishlistId);
      // setNotLoggedIn(false)
      // if (isRedirectPresent) {
      // } else {
      //   navigate(`/create-event-second-step/${currWishlistId}`);
      // }
    }else if(hasReminderPath){
      
      setLoginOrRegister({ approve: true });
    }
    else if  (pendingPresent) {

      alert("התחברת בהצלחה, מעבירים אותך להמשך קנית מתנה")
      navigate(`/`);
    } 

   else if (pendingAction && pendingAction.action === 'send-money') {
      alert("התחברת בהצלחה, מעבירים אותך להמשך קנית מתנה")
   }
     else {
      if (isRedirectPresent) {
        console.log("setNotLoggedIn");
        // setNotLoggedIn(false)
      } else {
        navigate("/");
        // window.location.reload();
        // setLoginOrRegister({ approve: true })
      }
    }
    window.scrollTo(0, 0);
  }

  return (
    <>
      <main
        className={`credentials-page ${isForm ? "credentials-page_form" : ""} ${
          isOrange ? "credentials-page_orange" : ""
        } ${isTopNavButtons ? "credentials-page_nav-buttons" : ""} ${
          registerStep.userDetails ? "register" : ""
        } ${isLoginOrRegister.choose ? "choose-login-or-register" : ""} ${
          isLoginWithPage.loginWithSms ? "login-with login-with_sms" : ""
        } ${registerStep.acceptTerms ? "terms-form" : ""} ${
          registerStep.afterRegister ? "after-register" : ""
        } ${isLoginWithPage.loginWithPhone ? "login-with" : ""}`}
      >
        <section
          className={`credentials-section ${
            isGreenMobile ? "green-mobile" : ""
          }`}
        >
          <div className="credentials-section__bg-image-ibg">
            <img
              src={mainBgImage.main}
              className="img-people"
              alt="bg"
            />
            <img
              src={mainBgImage.noPeople}
              className="img-no-people"
              alt="bg"
            />
          </div>
          <div className="credentials-section__content-window">
            {!loginStep.loginWithSms && (
              <div className="credentials-section__steps">
                <div
                  className={`credentials-section__steps-numbers numbers-steps ${
                    isOrange ? "numbers-steps_orange" : ""
                  } step-${stepNumber}`}
                >
                  <ul className="numbers-steps__list">
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">1</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">2</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">3</div>
                    </li>
                    <li className="numbers-steps__list-item">
                      <div className="numbers-steps__step">4</div>
                    </li>
                  </ul>
                </div>
              </div>
            )}

            <div className="credentials-section__content">
              {isLoginOrRegister.choose ? (
                <ChooseLoginOrRegister
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateRegisterStep={updateRegisterStep}
                  updateLoginStep={updateLoginStep}
                  updateLoginWith={updateLoginWith}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  setDiRegister={setDiRegister}
                />
              ) : (
                ""
              )}
              {isLoginOrRegister.register ? (
                <Register
                setLoginOrRegister={setLoginOrRegister}
                 transliterationMap ={ transliterationMap}                 
                 redirectAfterDetailsApproved={redirectAfterDetailsApproved}
                  isLoggedInHandler={isLoggedInHandler}
                  currUserObject={currUserObject}
                  setCurrUserObject={setCurrUserObject}
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  isTopNavButtons={isTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateVerifyNumberPopup={updateVerifyNumberPopup}
                  updateCodeNotRightPopup={updateCodeNotRightPopup}
                  updatePopupState={updatePopupState}
                  updateLoginWith={updateLoginWith}
                  registerStep={registerStep}
                  updateRegisterStep={updateRegisterStep}
                  updateIsForm={updateIsForm}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  updateLoginStep={updateLoginStep}
                  updateCodeNotRightMessage={updateCodeNotRightMessage}
                  codeNotRightMessage={codeNotRightMessage}
                  isRedirectPresent={isRedirectPresent}
                  setNotLoggedIn={setNotLoggedIn}
                  language={language}
                  languageToggle={languageToggle}
                  isCodeNotRightPopup={isCodeNotRightPopup}
                  resetOtpInputs={resetOtpInputs}
                  addUserIdToCreatedWishlist={addUserIdToCreatedWishlist}
                />
              ) : (
                ""
              )}
              {isLoginOrRegister.approve ? (
                <ApproveDetails
                  redirectAfterDetailsApproved={redirectAfterDetailsApproved}
                  isLoggedInHandler={isLoggedInHandler}
                  currUserObject={currUserObject}
                  setCurrUserObject={setCurrUserObject}
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  isTopNavButtons={isTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateVerifyNumberPopup={updateVerifyNumberPopup}
                  updateCodeNotRightPopup={updateCodeNotRightPopup}
                  updatePopupState={updatePopupState}
                  updateLoginWith={updateLoginWith}
                  registerStep={registerStep}
                  updateRegisterStep={updateRegisterStep}
                  updateIsForm={updateIsForm}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  updateLoginStep={updateLoginStep}
                  updateCodeNotRightMessage={updateCodeNotRightMessage}
                  codeNotRightMessage={codeNotRightMessage}
                  isRedirectPresent={isRedirectPresent}
                  setNotLoggedIn={setNotLoggedIn}
                  language={language}
                  languageToggle={languageToggle}
                  isCodeNotRightPopup={isCodeNotRightPopup}
                  resetOtpInputs={resetOtpInputs}
                  addUserIdToCreatedWishlist={addUserIdToCreatedWishlist}
                />
              ) : (
                ""
              )}
              {isLoginOrRegister.login ? (
                <Login
                  isLoggedInHandler={isLoggedInHandler}
                  currUserObject={currUserObject}
                  setCurrUserObject={setCurrUserObject}
                  updateLoginStep={updateLoginStep}
                  loginStep={loginStep}
                  isTopNavButtons={isTopNavButtons}
                  updateLoginOrRegister={updateLoginOrRegister}
                  updateStepNumber={updateStepNumber}
                  updateColorMode={updateColorMode}
                  updateTopNavButtons={updateTopNavButtons}
                  updateMainBgImage={updateMainBgImage}
                  updateLoginWith={updateLoginWith}
                  updateVerifyNumberPopup={updateVerifyNumberPopup}
                  updateCodeNotRightPopup={updateCodeNotRightPopup}
                  updatePopupState={updatePopupState}
                  updateIsForm={updateIsForm}
                  progress={progress}
                  updateProgress={updateProgress}
                  updateGreenMobile={updateGreenMobile}
                  updateRegisterStep={updateRegisterStep}
                  updateCodeNotRightMessage={updateCodeNotRightMessage}
                  codeNotRightMessage={codeNotRightMessage}
                  isRedirectPresent={isRedirectPresent}
                  setNotLoggedIn={setNotLoggedIn}
                  isCodeNotRightPopup={isCodeNotRightPopup}
                  resetOtpInputs={resetOtpInputs}
                  addUserIdToCreatedWishlist={addUserIdToCreatedWishlist}
                  doSuccessfullyLoggedinLogic={doSuccessfullyLoggedinLogic}
                  getOTPLimitObj={getOTPLimitObj}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
      </main>
      {isVerifyNumberPopup && (
        <WeSendYouSmsPopup
          updateVerifyNumberPopup={updateVerifyNumberPopup}
          updatePopupState={updatePopupState}
        />
      )}
    </>
  );
}

export default Credentials;
