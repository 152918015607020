import React, { useEffect, useState, useRef } from "react";
import Product from "../Components/Product";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import ImagePopup from "../Components/ImagePopup";
import { API_DOMAIN } from "../Libs/config";
import ItemAddedToCartPopup from "../Popups/item-added-to-cart-popup";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProductsFilter from "../Components/products-filter";
import arrowLeftBlack from "../Assets/img/svg/arrow-left-black.svg";
import ProductPlaceholder from "../Components/ProductPlaceholder";
import ProductNewEvent from "../Components/ProductNewEvent";
import Pagination from '@mui/material/Pagination';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PaginationItem from '@mui/material/PaginationItem';
import SelectedGiftsPopup from "../Popups/selected-gifts-popup";

SwiperCore.use([Navigation]);

const breakpoints = {
  768: {
    slidesPerView: 1,
    spaceBetween: 0,
  },
  1024: {
    slidesPerView: "auto",
    spaceBetween: 20,
  },
};

const theme = createTheme({
  components: {
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            display: 'flex',
            justifyContent: 'center',
            gap: '8px',
          },
          '& .MuiPaginationItem-root': {
            color: '#000',
            fontWeight: 'bold',
          },
          '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: '#000',
            color: '#fff',
          },
          '& .MuiPaginationItem-ellipsis': {
            color: '#000',
          },
        },
      },
    },
  },
});

export default function ProductsNewEvent({
  updatePopupState,
  openImagePopup,
  currWishlist,
  setCurrWishlist,
  currUserObject,
  filterToggle,
  allCategoriesList,
  newEvent,
  filterObject,
  setFilterObject,
  setShowCategMenuItem,
  setChoosedCategoriesList,
  isFilterOpen,
  eventType,
  setEventType,
  productsFilterObject,
  setProductsFilterObject,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { categoryId, productsId } = useParams();
  const [manufacturers, setManufacturers] = useState([]);
  const [rangePriceMinMax, setRangePriceMinMax] = useState({ min: null, max: null });
  const [productsPlaceholder, setProductsPlaceholder] = useState(false);
  const [isNewEvent, setNewEvent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [fetchError, setFetchError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const URLParams = new URLSearchParams(window.location.search);
  let currWishlistId = Number(URLParams?.get("wishlistId"));
  let additionalGift = Boolean(URLParams?.get("additionalGift"));
  const [currentCategory, setCurrentCategory] = useState(null);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(Number(URLParams.get('p')) || 1);
  const [totalPages, setTotalPages] = useState(1);
  const productsOffset = useRef(0);
  const mainSwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);
  const mainProductsRelated = useRef(null);
  const mainProductsRecent = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeIndexRecent, setActiveIndexRecent] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [isMobile, setIsMobile] = useState(false);
  const [relatedPrevActive, setRelatedPrevActive] = useState(true);
  const [recentPrevActive, setRecentPrevActive] = useState(true);
  const [columns, setColumns] = useState(JSON.parse(localStorage.getItem("productColumns")) || 3);
  const isSliderSaved = localStorage.getItem("productSlider");
  const [isSlider, setIsSlider] = useState(isSliderSaved ? JSON.parse(isSliderSaved) : true);
  const sliderMobile = useRef(null);
  const [mobileArrowPrev, setMobileArrowPrev] = useState(true);
  const [noProducts, setNoProducts] = useState(false);
  const [isItemAdded, setIsItemAdded] = useState({ added: false, prodPrice: 0, wishlistPrice: 0 });
  const [showSelectedGifts, setShowSelectedGifts] = useState(false);

  const addItemHandler = (state) => {
    setIsItemAdded(state);
  };

  const updatePopupSelectedGifts = (state)=>{
    setShowSelectedGifts(true)
  }

  useEffect(() => {
    setShowCategMenuItem(true);
  }, []);

  useEffect(() => {
    if (allCategoriesList) getCurrentCategory();
  }, [allCategoriesList]);

  const getCurrentCategory = () => {
    const currentMainCategory = allCategoriesList.find((category) => category.mainCategory.id === Number(categoryId));
    const currentProductsCategory = currentMainCategory?.productCategory?.find((category) => category.id === Number(productsId));
    setCurrentCategory(currentProductsCategory);
  };

  const continueEventCreation = () => {
    if (currUserObject) {
      navigate(`/create-event-second-step/${currWishlistId}`);
    } else {
      navigate(`/credentials-new-wishlist/${currWishlistId}`, { state: { currWishlistId } });
    }
  };

  const toggleProductsLayout = (state) => {
    setColumns(state);
    localStorage.setItem("productColumns", state);
  };

  const toggleSliderLayoutMobile = (state) => {
    setIsSlider(state);
    localStorage.setItem("productSlider", state);
  };

  const getCurrentWishlistObject = async (wishlistId) => {
    try {
      const response = await axios.get(`${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${wishlistId}`);
      if (response.data.result) {
        setCurrWishlist({
          whishlistItemsList: response.data.whishlistItemsList,
          whishlistObject: response.data.whishlistObject,
          whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
        });
        let totalWishlistPrice = 0;
        response.data.whishlistItemsDetailsList?.map((prod) => (totalWishlistPrice += Number(prod.price)));
        addItemHandler((prev) => ({
          ...prev,
          wishlistPrice: totalWishlistPrice,
          wishlistProductsNumber: response.data.whishlistItemsDetailsList?.length,
        }));
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      alert("Error, getting wishlist object");
    }
  };

  useEffect(() => {
    getCurrentWishlistObject(currWishlistId);
    if (!eventType) {
      const localEventType = localStorage.getItem("eventType");
      if (localEventType) setEventType(JSON.parse(localEventType));
    }
  }, []);

  useEffect(() => {
    if (mainProductsRelated.current?.swiper?.realIndex === 0) {
      setRelatedPrevActive(true);
    } else {
      setRelatedPrevActive(false);
    }
  }, [mainProductsRelated.current?.swiper?.realIndex]);

  useEffect(() => {
    if (sliderMobile.current?.swiper?.realIndex === 0) {
      setMobileArrowPrev(true);
    } else {
      setMobileArrowPrev(false);
    }
  }, [sliderMobile.current?.swiper?.realIndex]);

  useEffect(() => {
    if (mainProductsRecent.current?.swiper?.realIndex === 0) {
      setRecentPrevActive(true);
    } else {
      setRecentPrevActive(false);
    }
  }, [mainProductsRecent.current?.swiper?.realIndex]);

  useEffect(() => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      mainSwiperRef.current.swiper.on("slideChange", () => {
        setActiveIndex(mainSwiperRef.current.swiper?.realIndex);
      });
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
      setSlidesPerView(1);
    } else {
      setSlidesPerView(3);
      setIsMobile(false);
    }
    const handleResize = () => {
      setSlidesPerView(window.innerWidth < 768 ? 1 : 3);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    const isProdsNewEvent = window.location.href.includes("products-new-event");
    getProductsOnLoad();

    if (!currWishlist.whishlistObject) {
      axios
        .get(`${API_DOMAIN}/api/ProductCategory/GetWishlistObject?id=${currWishlistId}`)
        .then((response) => {
          if (response.data.result) {
            setCurrWishlist({
              whishlistItemsList: response.data.whishlistItemsList,
              whishlistObject: response.data.whishlistObject,
              whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
            });
          } else {
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          alert("Error, please try again");
        });
    }

    if (window.location.href.includes("new-event")) {
      setNewEvent(true);
    }
  }, []);

  const handleThumbnailClick = (index) => {
    const swiperInstance = mainSwiperRef.current?.swiper;
    if (swiperInstance) {
      swiperInstance.slideTo(index);
    }
  };

  const handlePrevNext = (direction) => {
    if (mainSwiperRef.current && mainSwiperRef.current.swiper) {
      const swiperInstance = mainSwiperRef.current.swiper;
      const swiperInstanceThumb = thumbnailSwiperRef.current.swiper;
      const currentIndex = swiperInstance?.realIndex;
      const lastIndex = swiperInstance.slides.length - 1;

      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? currentIndex + 1 : 0;
      }

      if (newIndex !== undefined) {
        swiperInstance.slideTo(newIndex);
        swiperInstanceThumb.slideTo(newIndex);
      }
    }
  };

  const handleSlideStep = (swiper, direction) => {
    if (swiper && swiper.slideTo) {
      const currentIndex = swiper?.realIndex;
      const lastIndex = swiper.slides.length - 1;
      let newIndex;
      if (direction === "prev") {
        newIndex = currentIndex > 0 ? currentIndex - 1 : lastIndex;
      } else if (direction === "next") {
        newIndex = currentIndex < lastIndex ? newIndex + 1 : 0;
      }
      if (newIndex !== undefined) {
        swiper.slideTo(newIndex);
      }
    }
  };

  const minMaxPriceHandler = (products) => {
    const pricesArr = products.map((product) => Number(product.price));
    const pricesSorted = pricesArr.sort((a, b) => a - b);
    const min = pricesSorted[0];
    const max = pricesSorted[pricesSorted.length - 1];
    return { minPrice: min, maxPrice: max };
  };

  const getProductsOnLoad = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const urlParams = url.searchParams;

    const productsRequestParams = {
      categoryList: urlParams.has("category") ? urlParams.get("category").split(",") : [productsId],
      priceFrom: urlParams.has("priceFrom") ? Number(urlParams.get("priceFrom")) : 0,
      priceTo: urlParams.has("priceTo") ? Number(urlParams.get("priceTo")) : 100000000,
      brand: urlParams.has("brand") ? urlParams.get("brand").split(",") : [],
      page: urlParams.has("p") ? Number(urlParams.get("p")) : 1,
    };

    setProductsFilterObject({ ...productsRequestParams });

    setChoosedCategoriesList([{ categoryId, productsId }]);

    setFilterObject((prevObj) => {
      const choosedCategoriesList = [...prevObj.choosedCategoriesList];
      const updatedChoosedCategoriesList = choosedCategoriesList.some(
        (category) =>
          category.categoryId === categoryId &&
          category.productsId === productsId,
      )
        ? choosedCategoriesList
        : [...choosedCategoriesList, { categoryId, productsId }];
      const newObj = {
        ...prevObj,
        choosedCategoriesList: updatedChoosedCategoriesList,
      };
      return newObj;
    });
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getProductsByParams = async (params, isLoadMore = false, retryCount = 0) => {
    setIsFetching(true);
    setFetchError(false);

    try {
      window.scrollTo(0,0);
      setProducts([]);

      const res = await axios.post(`${API_DOMAIN}/api/Product/filter`, {
        ...params,
        offset: (params.page - 1) * 84, // Use the page parameter to calculate the offset
        numberEntriesOnPage: 84,
      });

      if (res.data) {
        if (isLoadMore) {
          setProducts((prevProducts) => [...prevProducts, ...res.data.products]);
        } else {
          setProducts(res.data.products);
          setTotalPages(Math.ceil(res.data.totalCount / 84)); // Update total pages
        }

        setRangePriceMinMax({ min: res.data.minPrice, max: res.data.maxPrice });
      }
      setIsFetching(false);
    } catch (error) {
      if (retryCount < 3) {
        // Retry logic if needed
      } else {
        setIsFetching(false);
        setFetchError(true);
      }
    }
  };

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set('p', page);
    window.history.pushState({}, '', url);
    getProductsByParams({ ...productsFilterObject, page }, false, 0); // Pass the page in the params
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
    setProductsFilterObject((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const mainProductsFilter = (filterBy, value, state) => {
    productsOffset.current = 0;
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const urlParams = url.searchParams;

    let searchParams = { ...productsFilterObject };
    if (filterBy === "category") {
      if (state === true) {
        searchParams.categoryList.push(value);
      } else if (state === false) {
        searchParams.categoryList = searchParams.categoryList.filter(item => item !== value);
      }
      if (searchParams.categoryList.length > 0) {
        urlParams.set('category', searchParams.categoryList);
      } else {
        urlParams.delete('category');
      }
    } else if (filterBy === "brand") {
      if (state === true) {
        searchParams.brand.push(value);
      } else {
        searchParams.brand = searchParams.brand.filter(item => item !== value);
      }
      if (searchParams.brand.length > 0) {
        urlParams.set('brand', searchParams.brand);
      } else {
        urlParams.delete('brand');
      }
    } else if (filterBy === "price") {
      searchParams.priceFrom = value[0];
      searchParams.priceTo = value[1];
      urlParams.set('priceFrom', value[0]);
      urlParams.set('priceTo', value[1]);
    }

    setProductsFilterObject({ ...searchParams });
    window.history.replaceState({}, '', url.href);
  };


  const goBack = (e) => {
    e.preventDefault();
    navigate(-1, { replace: true }); // Use the replace option to avoid adding a new entry to the history stack
  };
  
  
  const updateSelectedGiftsState = (state)=>{
    setShowSelectedGifts(state);
  }
  const handleClick = () => {
    const url = `/all-categories-new-event?wishlistId=${currWishlistId}`;
    // Navigate to the URL once
    navigate(url);}
  return (
    <ThemeProvider theme={theme}>
      <main className={`products ${isNewEvent ? "new-event-type" : ""}`}>
        <section className="products__topbar topbar" style={isNewEvent ? { backgroundColor: "#DAD1CA" } : null}>
          {!isNewEvent && (
            <div className="topbar__bg-img-ibg">
              <img src="/img/common/topbar-products.png" alt="topbar" className="img-people" />
              <img src="/img/no-people-images/common/topbar-products.png" alt="topbar" className="img-no-people" />
            </div>
          )}

          <div className="topbar__container">
            <div className="topbar__title-wrapper" style={isNewEvent ? { position: "relative" } : null}>
              {isNewEvent && (
                <>
                 <button
                    type="button"
                    className="btn topbar__btn continue"
                    onClick={handleClick}
                    
                  >
                    בחזרה לרשימת הקטגוריות
                  </button>
                

                  <div className="topbar__bg-img-ibg topbar__bg-img-ibg--small topbar__bg-img-ibg--with-shadows">
                    <img src={"https://server.yyyshopping.com/" + eventType?.categoryImage} alt={eventType?.CategoryName} className="img-people" />
                  </div>
                </>
              )}

{isNewEvent && (
                <>
                 <button
                    type="button"
                    className="btn topbar__btn continue"
                    onClick={()=>{setShowSelectedGifts(true)}}
                    
                  >
                    צפייה ב-  Wishlist
                  </button>
                  <button
                    type="button"
                    className="btn topbar__btn continue"
                    onClick={(e) => {
                      if (additionalGift) {
                        navigate("/my-events", { state: { openTabNumber: 1 } });
                      } else {
                        continueEventCreation();
                      }
                    }}
                  >
                    {additionalGift ? "המשך ביצירת האירוע" : "המשך ביצירת האירוע"}
                  </button>

                  <div className="topbar__bg-img-ibg topbar__bg-img-ibg--small topbar__bg-img-ibg--with-shadows">
                    <img src={"https://server.yyyshopping.com/" + eventType?.categoryImage} alt={eventType?.CategoryName} className="img-people" />
                  </div>
                </>
              )}
              {!isNewEvent && (
                <h1 className="topbar__title">להמשיך ליצירת אירוע</h1>
              )}
            </div>
          </div>
        </section>

        <div className="products__breadcrumbs breadcrumbs">
          <div className="breadcrumbs__container">
            <ul className="breadcrumbs__list">
              <li className="breadcrumbs__item">
                <NavLink to="/">דף הבית</NavLink>
              </li>
              <li className="breadcrumbs__item">
                <NavLink to="/my-events">האירועים שלי</NavLink>
              </li>
              <li className="breadcrumbs__item">
                <NavLink to={`/all-categories?wishlistId=${currWishlistId}`}>
                  קטגוריות
                </NavLink>
              </li>
              <li className="breadcrumbs__item">
                <p>מוצרים</p>
              </li>
            </ul>
          </div>
        </div>

        <section className="products__main main-products">
          <div className="main-products__container">
            <div className="main-products__filter-btn-container filter-btn-container">
              <button type="button" className="filter-btn" onClick={filterToggle}>
                <img src="/img/svg/filter-icon.png" alt="filter icon" />
              </button>
            </div>

            <div className="main-products__steps">
              <div className="main-products__steps-numbers numbers-steps step-2">
                <ul className="numbers-steps__list">
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">1</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">2</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">3</div>
                  </li>
                  <li className="numbers-steps__list-item">
                    <div className="numbers-steps__step">4</div>
                  </li>
                </ul>
              </div>
            </div>

            <p className="main-products__pretitle">
              אין יותר מושלם מלבחור לעצמך את המתנה, עכשיו זה בידים שלך
            </p>
            <h2 className="main-products__title"></h2>

            <div className="main-products__view-type">
              <button
                type="button"
                onClick={() => toggleProductsLayout(3)}
                className={`main-products__view-type-btn ${columns === 3 ? "active" : ""}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19">
                  <g id="_3_בשורה" data-name="3 בשורה" transform="translate(-148 -309)">
                    <rect id="Прямоугольник_1938" data-name="Прямоугольник 1938" width="4" height="4" transform="translate(148 309)" />
                    <rect id="Прямоугольник_1939" data-name="Прямоугольник 1939" width="4" height="4" transform="translate(153 309)" />
                    <rect id="Прямоугольник_1940" data-name="Прямоугольник 1940" width="4" height="4" transform="translate(158 309)" />
                    <rect id="Прямоугольник_1944" data-name="Прямоугольник 1944" width="4" height="4" transform="translate(148 314)" />
                    <rect id="Прямоугольник_1945" data-name="Прямоугольник 1945" width="4" height="4" transform="translate(153 314)" />
                    <rect id="Прямоугольник_1946" data-name="Прямоугольник 1946" width="4" height="4" transform="translate(158 314)" />
                    <rect id="Прямоугольник_1950" data-name="Прямоугольник 1950" width="4" height="4" transform="translate(148 319)" />
                    <rect id="Прямоугольник_1951" data-name="Прямоугольник 1951" width="4" height="4" transform="translate(153 319)" />
                    <rect id="Прямоугольник_1952" data-name="Прямоугольник 1952" width="4" height="4" transform="translate(158 319)" />
                    <rect id="Прямоугольник_1957" data-name="Прямоугольник 1957" width="4" height="4" transform="translate(148 324)" />
                    <rect id="Прямоугольник_1958" data-name="Прямоугольник 1958" width="4" height="4" transform="translate(153 324)" />
                    <rect id="Прямоугольник_1959" data-name="Прямоугольник 1959" width="4" height="4" transform="translate(158 324)" />
                  </g>
                </svg>
              </button>
              <button
                type="button"
                onClick={() => toggleProductsLayout(4)}
                className={`main-products__view-type-btn ${columns === 4 ? "active" : ""}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                  <g id="_4_בשורה" data-name="4 בשורה" transform="translate(-148 -309)">
                    <rect id="Прямоугольник_1938" data-name="Прямоугольник 1938" width="4" height="4" transform="translate(148 309)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1939" data-name="Прямоугольник 1939" width="4" height="4" transform="translate(153 309)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1940" data-name="Прямоугольник 1940" width="4" height="4" transform="translate(158 309)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1941" data-name="Прямоугольник 1941" width="4" height="4" transform="translate(163 309)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1944" data-name="Прямоугольник 1944" width="4" height="4" transform="translate(148 314)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1945" data-name="Прямоугольник 1945" width="4" height="4" transform="translate(153 314)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1946" data-name="Прямоугольник 1946" width="4" height="4" transform="translate(158 314)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1947" data-name="Прямоугольник 1947" width="4" height="4" transform="translate(163 314)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1950" data-name="Прямоугольник 1950" width="4" height="4" transform="translate(148 319)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1951" data-name="Прямоугольник 1951" width="4" height="4" transform="translate(153 319)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1952" data-name="Прямоугольник 1952" width="4" height="4" transform="translate(158 319)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1953" data-name="Прямоугольник 1953" width="4" height="4" transform="translate(163 319)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1957" data-name="Прямоугольник 1957" width="4" height="4" transform="translate(148 324)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1958" data-name="Прямоугольник 1958" width="4" height="4" transform="translate(153 324)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1959" data-name="Прямоугольник 1959" width="4" height="4" transform="translate(158 324)" fill="#d8d8d8" />
                    <rect id="Прямоугольник_1960" data-name="Прямоугольник 1960" width="4" height="4" transform="translate(163 324)" fill="#d8d8d8" />
                  </g>
                </svg>
              </button>
            </div>
            <div className="main-products__mobile-header">
              <h2 className="main-products__header-title">התחל/' לבחור מתנות לסל</h2>

              <div className="main-products__view-type-mobile">
                <button onClick={() => toggleSliderLayoutMobile(false)} className={`main-products__view-type-mobile-btn ${isSlider ? "" : "active"}`}>
                  <svg
                    fill="#000"
                    width="64px"
                    height="64px"
                    viewBox="0 0 52 52"
                    data-name="Layer 1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path d="M50,15.52H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H50a2,2,0,0,1,2,2V13.52A2,2,0,0,1,50,15.52Zm-46-4H48V4H4Z"></path>
                      <path d="M50,33.76H2a2,2,0,0,1-2-2V20.24a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V31.76A2,2,0,0,1,50,33.76Zm-46-4H48V22.24H4Z"></path>
                      <path d="M50,52H2a2,2,0,0,1-2-2V38.48a2,2,0,0,1,2-2H50a2,2,0,0,1,2,2V50A2,2,0,0,1,50,52ZM4,48H48V40.48H4Z"></path>
                    </g>
                  </svg>
                </button>
                <button onClick={() => toggleSliderLayoutMobile(true)} className={`main-products__view-type-mobile-btn ${isSlider ? "active" : ""}`}>
                  <svg width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M18 7V17C18 17.62 17.98 18.17 17.91 18.66C17.62 21.29 16.38 22 13 22H11C7.62 22 6.38 21.29 6.09 18.66C6.02 18.17 6 17.62 6 17V7C6 6.38 6.02 5.83 6.09 5.34C6.38 2.71 7.62 2 11 2H13C16.38 2 17.62 2.71 17.91 5.34C17.98 5.83 18 6.38 18 7Z"
                        stroke="#000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M6 17.0001C6 17.6201 6.02 18.1701 6.09 18.6601C5.95 18.6701 5.82 18.6701 5.67 18.6701H5.33C2.67 18.6701 2 18.0001 2 15.3301V8.67008C2 6.00008 2.67 5.33008 5.33 5.33008H5.67C5.82 5.33008 5.95 5.33008 6.09 5.34008C6.02 5.83008 6 6.38008 6 7.00008V17.0001Z"
                        stroke="#000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M22 8.67008V15.3301C22 18.0001 21.33 18.6701 18.67 18.6701H18.33C18.18 18.6701 18.05 18.6701 17.91 18.6601C17.98 18.1701 18 17.6201 18 17.0001V7.00008C18 6.38008 17.98 5.83008 17.91 5.34008C18.05 5.33008 18.18 5.33008 18.33 5.33008H18.67C21.33 5.33008 22 6.00008 22 8.67008Z"
                        stroke="#000"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            {isMobile && isSlider ? (
              <Swiper
                ref={sliderMobile}
                style={{ position: "relative", marginTop: "30px" }}
                slidesPerView={1}
                onSlideChange={(swiper) => {
                  setActiveIndexRecent(swiper?.realIndex);
                }}
                className="custom-product-shadow"
              >
                {products?.length > 0
                  ? products.map((product, index) => (
                      <SwiperSlide
                        key={product.id}
                        className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""}`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <ProductNewEvent
                          key={product.id}
                          data={product}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                          currUserObject={currUserObject}
                          continueEventCreation={continueEventCreation}
                          origin="ProductsNewEvent"
                          
                        />
                      </SwiperSlide>
                    ))
                  : Array.from({ length: 10 }, (_, index) => (
                      <SwiperSlide
                        key={index}
                        className={`main-single-product__thumbs-slide swiper-slide ${index === activeIndex ? "active" : ""}`}
                        onClick={() => handleThumbnailClick(index)}
                      >
                        <ProductPlaceholder
                          key={index}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                          currUserObject={currUserObject}
                          placeholder={true}
                        />
                      </SwiperSlide>
                    ))}
                <div
                  className="main-products__list-nav"
                  style={{
                    position: "absolute",
                    display: "block",
                    zIndex: 11,
                    width: "100%",
                    top: "50%",
                  }}
                >
                  <div
                    className="main-products__list-prev"
                    style={{display:'none'}}
                //    style={{ display: `${mobileArrowPrev ? "none" : "flex"}` }}
                    onClick={() =>
                      handleSlideStep(sliderMobile.current?.swiper, "prev")
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                  <div
                    className="main-products__list-next"
                    style={{display: 'none'}}
                 //   style={{
                   //   display: `${
                     //   sliderMobile.current?.swiper?.slides.length - 1 ===
                       // sliderMobile.current?.swiper?.realIndex
                         // ? "none"
                          //: "flex"
                     // }`,
                    //}}
                    onClick={() =>
                      handleSlideStep(sliderMobile.current?.swiper, "next")
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                </div>
              </Swiper>
            ) : (
              <div className="main-products__list-wrapper swiper">
                <ul
                  className={`main-products__list products-list swiper-wrapper ${
                    isSlider === false && isMobile === true ? "column" : ""
                  } ${columns === 4 ? "four-columns" : ""}`}
                >
                  {productsPlaceholder
                    ? Array.from({ length: 10 }, (_, index) => (
                        <ProductPlaceholder
                          key={index}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                          currUserObject={currUserObject}
                          placeholder={true}
                        />
                      ))
                    : products?.length > 0
                    ? products.map((product) => (
                        <ProductNewEvent
                          key={product.id}
                          data={product}
                          updatePopupState={updatePopupState}
                          addItemHandler={addItemHandler}
                          openImagePopup={openImagePopup}
                          currWishlist={currWishlist}
                          setCurrWishlist={setCurrWishlist}
                          currUserObject={currUserObject}
                          origin="ProductsNewEvent"
                        />
                      ))
                    : noProducts && <p>אין מוצרים זמינים</p>}
                </ul>
                {isFetching && (
                  <div className="main-products__loading" style={{ marginTop: "30px" }}>
                    <p>טוען מוצרים...</p>
                  </div>
                )}

                <div
                  className="main-products__list-view-point"
                  style={{
                    height: 1,
                    width: 1,
                    opacity: 0,
                    visibility: "hidden",
                  }}
                ></div>
              </div>
            )}
{(!isFetching && !isMobile) &&
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              siblingCount={1}
              boundaryCount={1}
        
              style={{ marginTop: '20px' }}
              renderItem={(item) => (
                <PaginationItem
                slots={{ 
                  previous: () => <span style={{ fontSize: isMobile ? '20vw' : '2vw'}}>&#8249;</span>, 
                  next: () => <span style={{fontSize: isMobile ? '20vw' : '2vw'}}>&#8250;</span> 
              }}
                                {...item}
                />
              )}
            />}
          </div>
        </section>

        <section className="products__main main-products main-products_related">
          <div className="main-products__container">
            <h2 className="main-products__title">מוצרים נילווים</h2>
            <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

            <div className="main-products__slider-block">
              <div className="main-products__slider swiper">
                <div className="main-products__wrapper swiper-wrapper">
                  <Swiper
                    style={{
                      position: "relative",
                      marginTop: "30px",
                      padding: "0.52083vw",
                    }}
                    ref={mainProductsRelated}
                    slidesPerView={slidesPerView}
                    spaceBetween={40}
                    onSlideChange={(swiper) => {
                      setActiveIndexRecent(swiper?.realIndex);
                    }}
                  >
                    {/* Related products can be added here as SwiperSlides */}
                  </Swiper>
                </div>

                <div className="main-products__slider-nav">
                  <div
                    className="main-products__slider-prev"
                    style={{
                      display: `${relatedPrevActive ? "none" : "flex"}`,
                    }}
                    onClick={() =>
                      handleSlideStep(
                        mainProductsRelated.current.swiper,
                        "prev",
                      )
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                  <div
                    className="main-products__slider-next"
                    style={{
                      display: `${
                        mainProductsRelated.current?.swiper.slides.length - 3 ===
                        mainProductsRelated.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                      }`,
                    }}
                    onClick={() =>
                      handleSlideStep(
                        mainProductsRelated.current.swiper,
                        "next",
                      )
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="main-products__btn-container btn-container_center">
              <a href="#" className="btn-outline-white">
                לא מצאתי רוצה לראות עוד מוצרים
              </a>
            </div>
          </div>
        </section>

        <section className="products__main main-products main-products_recent">
          <div className="main-products__container">
            <h2 className="main-products__title">מוצרים אחרונים שחיפשתי</h2>
            <h3 className="main-products__subtitle">שיתאימו לך בול</h3>

            <div className="main-products__slider-block">
              <div className="main-products__slider swiper">
                <div className="main-products__wrapper swiper-wrapper">
                  <Swiper
                    style={{
                      position: "relative",
                      marginTop: "30px",
                      padding: "0.52083vw",
                    }}
                    ref={mainProductsRecent}
                    slidesPerView={slidesPerView}
                    spaceBetween={40}
                    onSlideChange={(swiper) => {
                      setActiveIndexRecent(swiper?.realIndex);
                    }}
                  >
                    {/* Recently viewed products can be added here as SwiperSlides */}
                  </Swiper>
                </div>

                <div className="main-products__slider-nav">
                  <div
                    className="main-products__slider-prev"
                    style={{
                      display: `${recentPrevActive ? "none" : "flex"}`,
                    }}
                    onClick={() =>
                      handleSlideStep(mainProductsRecent.current.swiper, "prev")
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                  <div
                    className="main-products__slider-next"
                    style={{
                      display: `${
                        mainProductsRecent.current?.swiper.slides.length - 3 ===
                        mainProductsRecent.current?.swiper?.realIndex
                          ? "none"
                          : "flex"
                      }`,
                    }}
                    onClick={() =>
                      handleSlideStep(mainProductsRecent.current.swiper, "next")
                    }
                  >
                    <img src={arrowLeftBlack} alt="" />
                  </div>
                </div>
              </div>

              <div className="main-products__btn-container btn-container_center">
                <a href="#" className="btn-outline-white">
                  לא מצאתי רוצה לראות עוד מוצרים
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
      {isFilterOpen && (
        <ProductsFilter
          filterToggle={filterToggle}
          allCategoriesList={allCategoriesList}
          filterObject={filterObject}
          setFilterObject={setFilterObject}
          productsFilterObject={productsFilterObject}
          setProductsFilterObject={setProductsFilterObject}
          filterFunction={mainProductsFilter}
          manufacturers={manufacturers}
          rangePriceMinMax={rangePriceMinMax}
        />
      )}

      {isItemAdded.added && (
        <ItemAddedToCartPopup
          updatePopupSelectedGifts = {updatePopupSelectedGifts}
          updatePopupState={updatePopupState}
          addItemHandler={addItemHandler}
          isItemAdded={isItemAdded}
          currWishlist={currWishlist}
          currUserObject={currUserObject}
          setCurrWishlist={setCurrWishlist}
        />
      )}

{showSelectedGifts && (
        <SelectedGiftsPopup
          updateSelectedGiftsState = {updateSelectedGiftsState}
          currWishlist={currWishlist}
    
        />
      )}
    </ThemeProvider>
  );
}
