import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";

import {
  bellOutline,
  discoBall,
  homeBg,
  homeBgNoPeople,
  logoImg,
} from "../Assets/images";

function Home({
  setMyEventsActiveTab,
  currUserObject,
  setCurrWishlist,
}) {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const currentUser = localStorage.getItem("currUserObject");
    console.log(currentUser);
    setCurrentUser(currentUser?.user);
    const params = new URLSearchParams(window.location.search);
    const inviteWishlistId = Boolean(params?.get("inviteWishlistId"));
    if (!currentUser && !inviteWishlistId) {
      navigate("/credentials");
    }
  }, []);
const goToFutureEvents =()=>{
  setMyEventsActiveTab(4);
  navigate('/my-events')
}
  const openMyEvents2Tab = (e) => {
    e.preventDefault()
    navigate('/my-events', { state: { openTabNumber: 1 } })
  }

  const openMyEvents4Tab = (e) => {
    e.preventDefault();
    navigate('/my-events', { state: { openTabNumber: 3 } })
  }
  const goToCartByWishlist = () => {
    axios
      .get(
        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=3d926775-bfba-4b43-8099-32ba025c7ea3`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong !!!!!!!!!!!!!!");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });

  };
  return (
    <main className="home-page credentials-page" data-fullscreen>
      <section className="credentials-section">
        <div className="credentials-section__bg-image-ibg">
          <img src={homeBg} className="img-people" alt="bg" />
          <img src={homeBgNoPeople} className="img-no-people" alt="bg" />
        </div>
        <div className="credentials-section__content-window">
          <div className="credentials-section__content">
            <div className="home-page__logo">
              <img src={logoImg} alt="img" />
            </div>
            <ul className="home-page__links-list">
              <li className="home-page__links-item">
                <NavLink
                    to="http://localhost:3000/my-bank"

              //   onClick={() => goToCartByWishlist()}
                  className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.846"
                      height="24.798"
                      viewBox="0 0 19.846 24.798"
                    >
                      <g
                        id="Сгруппировать_74057"
                        data-name="Сгруппировать 74057"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Artboard_2"
                          data-name="Artboard 2"
                          d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                          transform="translate(-74.992 -18.61)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth={1}
                        />
                        <path
                          id="heart"
                          d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                          transform="translate(-25.26 -15.21)"
                          fill="#ec1b53"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">רכישת מתנות</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <botton 
                onClick={goToFutureEvents}
                 className="home-page__link">
                  <span className="icon">
                    <img src={bellOutline} alt="" />
                  </span>
                  <span className="text">אירועים עתידיים</span>
                </botton>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/my-bank" className="home-page__link">
                  <span className="icon">
                  <svg
                  id="Artboard_1"
                  data-name="Artboard 1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.901"
                  height="24.498"
                  viewBox="0 0 23.901 24.498"
                >
                  <path
                    id="Контур_10114"
                    data-name="Контур 10114"
                    d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                    transform="translate(6.338 -33.519)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10115"
                    data-name="Контур 10115"
                    d="M16,54h.891v.891H16V54Z"
                    transform="translate(6.12 -35.737)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10116"
                    data-name="Контур 10116"
                    d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                    transform="translate(-15.361 -30.746)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10117"
                    data-name="Контур 10117"
                    d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                    transform="translate(-21 -29.083)"
                    fill="#707070"
                  />
                  <rect
                    id="Прямоугольник_2304"
                    data-name="Прямоугольник 2304"
                    width="1.4"
                    height="2.8"
                    transform="translate(7.107 2.8)"
                    fill="#707070"
                  />
                  <rect
                    id="Прямоугольник_2305"
                    data-name="Прямоугольник 2305"
                    width="0.7"
                    height="2.8"
                    transform="translate(9.207)"
                    fill="#707070"
                  />
                  <rect
                    id="Прямоугольник_2306"
                    data-name="Прямоугольник 2306"
                    width="0.7"
                    height="2.8"
                    transform="translate(13.407 8.399)"
                    fill="#707070"
                  />
                  <rect
                    id="Прямоугольник_2307"
                    data-name="Прямоугольник 2307"
                    width="1.4"
                    height="1.4"
                    transform="translate(14.807 11.899)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10118"
                    data-name="Контур 10118"
                    d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                    transform="translate(-18.31 -13.535)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10119"
                    data-name="Контур 10119"
                    d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                    transform="translate(-20.664 -14.17)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10120"
                    data-name="Контур 10120"
                    d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                    transform="translate(-42.772 -20.762)"
                    fill="#707070"
                  />
                  <path
                    id="Контур_10121"
                    data-name="Контур 10121"
                    d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                    transform="translate(-37.854 -20.969)"
                    fill="#707070"
                  />
                  <rect
                    id="Прямоугольник_2308"
                    data-name="Прямоугольник 2308"
                    width="0.7"
                    height="0.7"
                    transform="translate(9.207 3.5)"
                    fill="#707070"
                  />
                </svg>
                  </span>
                  <span className="text">כמה כסף יש לי בקופה</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink
                  style={{background:'linear-gradient(137deg, #ec1b53 0%, #ec1b53 33.3333%,  #ec1b53 66.6666%, #ec1b53 100%)'}}
                  to="/my-events"
                  onClick={() => setMyEventsActiveTab(0)}
                  className="home-page__link">
                  <span className="icon">
                    <svg
                      id="Компонент_565_1"
                      data-name="Компонент 565 – 1"
                      xmlns="http://www.w3.org/2000/svg"
                      width="38.082"
                      height="38.945"
                      viewBox="0 0 38.082 38.945"
                    >
                      <g
                        id="Контур_10101"
                        data-name="Контур 10101"
                        transform="translate(0 0.863)"
                        fill="#fff"
                      >
                        <path
                          d="M 19.04082870483398 37.58165740966797 C 16.5376091003418 37.58165740966797 14.10953903198242 37.09150695800781 11.82405853271484 36.12482833862305 C 9.616289138793945 35.1910285949707 7.633398532867432 33.85409927368164 5.930478572845459 32.15118026733398 C 4.227558612823486 30.4482593536377 2.890628814697266 28.46536827087402 1.956828713417053 26.25759887695312 C 0.9901487231254578 23.97211837768555 0.4999987185001373 21.54404830932617 0.4999987185001373 19.04082870483398 C 0.4999987185001373 16.5376091003418 0.9901487231254578 14.10953903198242 1.956828713417053 11.82405853271484 C 2.890628814697266 9.616289138793945 4.227558612823486 7.633398532867432 5.930478572845459 5.930478572845459 C 7.633398532867432 4.227558612823486 9.616289138793945 2.890628814697266 11.82405853271484 1.956828713417053 C 14.10953903198242 0.9901487231254578 16.5376091003418 0.4999987185001373 19.04082870483398 0.4999987185001373 C 21.54404830932617 0.4999987185001373 23.97211837768555 0.9901487231254578 26.25759887695312 1.956828713417053 C 28.46536827087402 2.890628814697266 30.4482593536377 4.227558612823486 32.15118026733398 5.930478572845459 C 33.85409927368164 7.633398532867432 35.1910285949707 9.616289138793945 36.12482833862305 11.82405853271484 C 37.09150695800781 14.10953903198242 37.58165740966797 16.5376091003418 37.58165740966797 19.04082870483398 C 37.58165740966797 21.54404830932617 37.09150695800781 23.97211837768555 36.12482833862305 26.25759887695312 C 35.1910285949707 28.46536827087402 33.85409927368164 30.4482593536377 32.15118026733398 32.15118026733398 C 30.4482593536377 33.85409927368164 28.46536827087402 35.1910285949707 26.25759887695312 36.12482833862305 C 23.97211837768555 37.09150695800781 21.54404830932617 37.58165740966797 19.04082870483398 37.58165740966797 Z"
                          stroke="none"
                        />
                        <path
                          d="M 19.04082870483398 1 C 14.22194862365723 1 9.691497802734375 2.876567840576172 6.284038543701172 6.284038543701172 C 2.876567840576172 9.691497802734375 1 14.22194862365723 1 19.04082870483398 C 1 23.85970878601074 2.876567840576172 28.39015960693359 6.284038543701172 31.7976188659668 C 9.691497802734375 35.2050895690918 14.22194862365723 37.08165740966797 19.04082870483398 37.08165740966797 C 23.85970878601074 37.08165740966797 28.39015960693359 35.2050895690918 31.7976188659668 31.7976188659668 C 35.2050895690918 28.39015960693359 37.08165740966797 23.85970878601074 37.08165740966797 19.04082870483398 C 37.08165740966797 14.22194862365723 35.2050895690918 9.691497802734375 31.7976188659668 6.284038543701172 C 28.39015960693359 2.876567840576172 23.85970878601074 1 19.04082870483398 1 M 19.04082870483398 0 C 29.55678939819336 0 38.08165740966797 8.524868011474609 38.08165740966797 19.04082870483398 C 38.08165740966797 29.55678939819336 29.55678939819336 38.08165740966797 19.04082870483398 38.08165740966797 C 8.524868011474609 38.08165740966797 0 29.55678939819336 0 19.04082870483398 C 0 8.524868011474609 8.524868011474609 0 19.04082870483398 0 Z"
                          stroke="none"
                          fill="#707070"
                        />
                      </g>
                      <text
                        id="_"
                        data-name="+"
                        transform="translate(18.695 29)"
                        fill="#3c9e1c"
                        fontSize={28}
                        fontFamily="Assistant-Bold, Assistant"
                        fontWeight={700}
                      >
                        <tspan x="7.322" y={0}>
                          +
                        </tspan>
                      </text>
                    </svg>
                  </span>
                  <span className="text">פתיחת אירוע חדש</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <button to="/my-events"
                  onClick={openMyEvents2Tab}
                  className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="44.82"
                      height="40.153"
                      viewBox="0 0 44.82 40.153"
                    >
                      <g
                        id="Artboard_3"
                        data-name="Artboard 3"
                        transform="translate(0 0.5)"
                      >
                        <g
                          id="Сгруппировать_74363"
                          data-name="Сгруппировать 74363"
                          transform="translate(6.231)"
                        >
                          <g
                            id="Сгруппировать_74361"
                            data-name="Сгруппировать 74361"
                          >
                            <path
                              id="Контур_38424"
                              data-name="Контур 38424"
                              d="M33.36,33.64C37.725,38,42.347,40.454,43.688,39.1"
                              transform="translate(-17.129 -10.75)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38425"
                              data-name="Контур 38425"
                              d="M32.4,31.948c-4.365-4.365-6.814-8.987-5.465-10.328"
                              transform="translate(-16.172 -9.058)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38426"
                              data-name="Контур 38426"
                              d="M14.633,45.712a1.822,1.822,0,0,0,.275,1.968,1.856,1.856,0,0,0,1.968.275s22.108-8.549,23.551-9.993l.61-.61c1.34-1.34-1.108-5.963-5.465-10.328s-8.987-6.814-10.328-5.465l-.61.61c-1.443,1.443-9.993,23.551-9.993,23.551Z"
                              transform="translate(-14.469 -8.997)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_526"
                              data-name="Линия 526"
                              y2="8.592"
                              transform="translate(17.641 24.058)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_527"
                              data-name="Линия 527"
                              y2="14.607"
                              transform="translate(13.344 19.762)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_528"
                              data-name="Линия 528"
                              y2="20.948"
                              transform="translate(9.049 15.14)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <line
                              id="Линия_529"
                              data-name="Линия 529"
                              y2="12.648"
                              transform="translate(4.752 25.158)"
                              fill="none"
                              stroke="#707070"
                              strokeMiterlimit={10}
                              strokeWidth={1}
                            />
                            <circle
                              id="Эллипс_414"
                              data-name="Эллипс 414"
                              cx="2.578"
                              cy="2.578"
                              r="2.578"
                              transform="translate(18.5)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38427"
                              data-name="Контур 38427"
                              d="M39,34.011c3.334-4.133,8.592-7.767,12.888-5.155,3.626,2.2,2.217,5.619-.069,5.98s-3.583-1.65-3.368-2.543"
                              transform="translate(-17.924 -9.953)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38428"
                              data-name="Контур 38428"
                              d="M56.178,15.2l-.868,3,1.985,2.475-3.351.232-1.675,2.44L50.98,20.565l-2.87-1.143L50.567,17.6l.1-3.351,2.543,1.907Z"
                              transform="translate(-19.205 -8.021)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38429"
                              data-name="Контур 38429"
                              d="M30.674,24.161c2.268-4.322,3.695-11.041-.378-12.192-2.543-.713-4.3.644-4.3,2.578a2.143,2.143,0,0,0,3.437,1.718"
                              transform="translate(-16.094 -7.672)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                            <path
                              id="Контур_38430"
                              data-name="Контур 38430"
                              d="M44.16,7.2A8.715,8.715,0,0,1,40.8,19.057"
                              transform="translate(-18.176 -7.028)"
                              fill="none"
                              stroke="#707070"
                              strokeLinejoin="round"
                              strokeWidth={1}
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  <span className="text">האירועים שלי</span>
                </button>
              </li>
              <li className="home-page__links-item">
                <button
                  //  to="/my-events" 
                  onClick={openMyEvents4Tab}
                  className="home-page__link">
                  <span className="icon">
                    <img src={discoBall} alt="" />
                  </span>
                  <span className="text">האירועים שהוזמנתי</span>
                </button>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/edit-profile" className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.067"
                      height="24.067"
                      viewBox="0 0 24.067 24.067"
                    >
                      <g
                        id="Компонент_165_29"
                        data-name="Компонент 165 – 29"
                        transform="translate(0.5 0.5)"
                      >
                        <path
                          id="Объединение_74"
                          data-name="Объединение 74"
                          d="M0,23.067V20.184c0-3.172,5.19-5.767,11.533-5.767s11.533,2.6,11.533,5.767v2.883Zm5.766-17.3a5.767,5.767,0,1,1,5.767,5.766A5.767,5.767,0,0,1,5.766,5.767Z"
                          fill="none"
                          stroke="#707070"
                          strokeWidth={1}
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">פרטים אישיים</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/credentials" className="home-page__link">
                  <span className="icon">
                    <svg
                      width="64px"
                      height="64px"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <g id="SVGRepo_iconCarrier">
                        <rect
                          width={48}
                          height={48}
                          fill="white"
                          fillOpacity="0.01"
                        />
                        <path
                          d="M23.9917 6L6 6L6 42H24"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M25 33L16 24L25 15"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M42 23.9917L16 23.9917"
                          stroke="#000000"
                          strokeWidth={4}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">כניסה והרשמה</span>
                </NavLink>
              </li>
              <li className="home-page__links-item">
                <NavLink to="/contact-us" className="home-page__link">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={27}
                      height={26}
                      viewBox="0 0 27 26"
                    >
                      <g
                        id="Сгруппировать_1499"
                        data-name="Сгруппировать 1499"
                        transform="translate(1177 -2115)"
                      >
                        <g
                          id="Эллипс_411"
                          data-name="Эллипс 411"
                          transform="translate(-1177 2115)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <g
                          id="Эллипс_412"
                          data-name="Эллипс 412"
                          transform="translate(-1159 2122)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <g
                          id="Эллипс_413"
                          data-name="Эллипс 413"
                          transform="translate(-1175 2132)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        >
                          <circle cx="4.5" cy="4.5" r="4.5" stroke="none" />
                          <circle cx="4.5" cy="4.5" r="3.75" fill="none" />
                        </g>
                        <line
                          id="Линия_524"
                          data-name="Линия 524"
                          x2={10}
                          y2={4}
                          transform="translate(-1168.5 2120.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        />
                        <line
                          id="Линия_525"
                          data-name="Линия 525"
                          y1={6}
                          x2={9}
                          transform="translate(-1166.5 2128.5)"
                          fill="none"
                          stroke="#707070"
                          strokeWidth="1.5"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">צור קשר</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>

    </main>

  );
}

export default Home;
