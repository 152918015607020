import React, { useEffect, useRef, useState } from "react";
import { greenPopupBg } from "../Assets/images";
import useClickOutside from "../hooks/useClickOutside";

function EventNotRelevant({ updatePopupState, onClick, handleClose }) {

  const refIsEventExpPopup = useClickOutside(handleClose);


  useEffect(() => {
    updatePopupState(true);
  }, []);


  return (
    <div
  id="code-not-right-popup-pink"
  aria-hidden="true"
  className="popup popup_show"
>
  <div className="popup__wrapper">
    <div className="popup__content" ref={refIsEventExpPopup}>
      <div className="popup__bg-ibg">
        <img src={greenPopupBg} alt="pink hearts" />
      </div>
      <button
        data-close=""
        type="button"
        onClick={onClick}
        className="popup__close"
      />
      <div className="popup__body">
        <div className="popup__image-ibg_contain">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="103.089"
            height={124}
            viewBox="0 0 103.089 124"
          >
            <g
              id="Сгруппировать_1194"
              data-name="Сгруппировать 1194"
              transform="translate(-3955.911 -866.532)"
            >
              <path
                id="Контур_10156"
                data-name="Контур 10156"
                d="M53.133,17.033a9.7,9.7,0,0,1,16.644,0l41.831,69.895a9.7,9.7,0,0,1-8.322,14.679H19.624A9.7,9.7,0,0,1,11.3,86.927L27.858,59.264Z"
                transform="translate(3946 871.572)"
                fill="#f8af1d"
              />
              <text
                id="_"
                data-name="!"
                transform="translate(4007.455 962.532)"
                fill="none"
                stroke="#fff"
                strokeWidth={1}
                fontSize={93}
                fontFamily="Assistant-Bold, Assistant"
                fontWeight={700}
              >
                <tspan x="15.438" y={0}>
                  !
                </tspan>
              </text>
            </g>
          </svg>
        </div>
        <h3 className="popup__title">אירוע לא רלונטי</h3>
        <div className="popup__buttons-container">
          <button onClick={onClick} className="btn-white">
            סגור
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default EventNotRelevant;
