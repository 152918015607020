import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { API_DOMAIN } from "../Libs/config";

export default function SelectedGiftsPopup({
  updateSelectedGiftsState,
  updateSelState,
  addItemHandler,
  isItemAdded,
  currWishlist,
  currUserObject,
  setCurrWishlist,
}) {
  const URLParams = new URLSearchParams(window.location.search);
  const currWishlistId = URLParams?.get("wishlistId");
  const additionalGift = Boolean(URLParams?.get("additionalGift"));
  const [showSelectedGifts, setShowSelectedGifts] = useState(false);
  const [selectedGifts, setSSelectedGifts] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [whoAlsoBought, ss] = useState([])


  useEffect(() => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    
  }, []);
  useEffect(()=>{
    console.log("^^^^^^^^^^^^^^^^^^^^^^^", isMobile)

  },[isMobile])


  const closePopupHandler = () => {
    updateSelectedGiftsState(false)
  };
useEffect(()=>{
console.log("%%%%%%%%%%%%%%%%%%%%%", currWishlist);
},[])
  return (
    <>
 <div
   
      id="already-bought-popup"
      style={{flex: '0 0 9.0625vw',  fontSize: isMobile ? '0.905rem' : 'initial'}}
      aria-hidden="true"
      className="popup popup_show credentials-section__form-wrapper"
      data-fullscreen=""
    >
      <div className="popup__wrapper">
        <div className="popup__content" style={{maxWidth: !isMobile? '35.927083vw': '100vw'}}>
          <button
            data-close=""
            type="button"
            className="popup__close already-bought"
            onClick={() => closePopupHandler()}
          />
          <div className="popup__body">
            <h1 className="popup__title" style={{color: '#ec1b53'}}>מתנות שבחרת</h1>
            <ul className="popup__list hi">

              {currWishlist?.whishlistItemsDetailsList?.map((person, index) => (

                
                <li key={index} className="popup__list-item" style={{ marginTop: '4px'}}>
                  <div className="avatar">
                   {/* <img src={person.userPic ? API_DOMAIN_IMG + "/server-images/" + person.userPic :  alreadyBought } alt="" />*/}
                  </div>
                  <p className="my-event-single__image-ibg">
                 {person?.image && <img src={person.image} alt="Person" />}
                 </p>
                  <p className="text name">{person?.productName}</p>
                  <p className="text product">₪{person?.price} </p>
               

                </li>
              ))}

            </ul>
          </div>
        </div>
      </div>
    </div>    </>
  );
}
