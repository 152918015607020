import React, { useEffect, useRef, useState } from "react";
import {
  Routes,
  Route,
  NavLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import { API_DOMAIN, API_DOMAIN_IMG } from "../Libs/config";
import logoImg from "../Assets/img/logo.png";
import {
  arrowLeftWhite,
  facebook,
  instagram,
  userIcon,
  whatsapp,
} from "../Assets/images";
import CustomSelect from "../Components/CustomSelect";
import { bellOutline } from "../Assets/images";
import { catIconSeventh } from "../Libs/categoryIcons";

import { present1, steps1 } from "../Assets/images";


const navigationData = [
  // navigation list with and without submenues
  {
    text: "דף הבית",
    url: "/home",
  },
  {
    text: "איך זה עובד?",
    url: "/explaining-steps",
    submenu: [
      {
        text: "מזמינים",
        url: "/explaining-steps",
      },
      {
        text: "מוזמנים",
        url: "/explaining-steps-2",
      },
    ],
  },
  {
    text: "אירוע חדש",
    url: "/my-events",
  },
  {
    text: "האירועים שלי",
    url: "/my-events",
    submenu: [
      {
        text: "האירועים שלי",
        url: "/my-events",
      },
      {
        text: "היסטוריית אירועים",
        url: "/my-events",
      },
      {
        text: "האירועים שהוזמנתי",
        url: "/my-events",
      },
    ],
  },
  {
    text: "הקופה שלי",
    url: "/my-bank",
  },
  {
    text: "תנאי שימוש",
    url: "/terms",
  },
  {
    text: "צור קשר",
    url: "/contact-us",
  },

];

const navigationDataNotLogged = [

  {
    text: "איך זה עובד?",
    url: "/explaining-steps",
    submenu: [
      {
        text: "מזמינים",
        url: "/explaining-steps",
      },
      {
        text: "מוזמנים",
        url: "/explaining-steps-2",
      },
    ],
  },
  {
    text: "אירוע חדש",
    url: "/my-events",
  },

  {
    text: "תנאי שימוש",
    url: "/terms",
  },
  {
    text: "צור קשר",
    url: "/contact-us",
  },
  {
    text: "כניסה והרשמה",
    url: "/credentials",
  },
];

let isFirstLoad = true;

function Header({
  isLoggedIn,
  isLoggedInHandler,
  setIsloggedIn,
  burgerToggle,
  setMyEventsActiveTab,
  allCategoriesList,
  setCurrWishlist,
  filterObject,
  setFilterObject,
  currUserObject,
  showCategMenuItem,
  productsFilterObject,
  setProductsFilterObject,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false); //true for developing ppss
  const linkSubmenuRef = useRef(null);
  const subMenuRef = useRef(null);
  const [userName, setUserName] = useState("name");
  const [isLoggedInReload, setIsloggedInReload] = useState(
    localStorage.getItem("currUserObject") != undefined,
  );
  const wishlist = location?.state?.activeWishlist;
  console.log("debugHeader currUserObject", currUserObject);
  const [redirectLink, setRedirectLink] = useState("");
  let currWishlistId = null;
  if (showCategMenuItem) {
    const URLParams = new URLSearchParams(window.location.search);
    currWishlistId = URLParams?.get("wishlistId");
  }

  const handleGoBack = () => {
    console.log("handleGoBack");
    navigate(-1); // Navigate back
  };
  useEffect(() => {
    setIsloggedIn(isLoggedInReload);
  }, []);
  useEffect(() => {
    if (location.pathname.includes("my-bank")) {
      setRedirectLink("my-bank-products");
    } else {
      setRedirectLink("products-new-event");
    }

    return () => { };
  }, [location.pathname]);

  const handleMyEventLinkClick = () => {
    navigate("/my-events");
    setMyEventsActiveTab(1);
  };
  // Event listener for link mouse enter
  const handleLinkMouseEnter = () => {
    setIsSubMenuVisible(true);
  };

  // Event listener for link mouse leave
  const handleLinkMouseLeave = (event) => {
    const toElement = event.relatedTarget;
    if (!subMenuRef.current?.contains(toElement)) {
      setIsSubMenuVisible(false);
    }
  };

  // Event listener for submenu mouse enter
  const handleSubMenuMouseEnter = () => {
    setIsSubMenuVisible(true);
  };

  // Event listener for submenu mouse leave
  const handleSubMenuMouseLeave = (event) => {
    const toElement = event.relatedTarget;
    const isDomElement = toElement instanceof HTMLElement;
    // console.log("handleSubMenuMouseLeave: isDomElement", isDomElement);
    if (!isDomElement) return;
    if (!linkSubmenuRef.current?.contains(toElement)) {
      setIsSubMenuVisible(false);
    }
  };
  const goToCartByWishlist = () => {
    axios
      .get(

        `${API_DOMAIN}/api/ProductCategory/GetWishlistList?userId=${currUserObject?.user.id}`,
      )
      .then((response) => {
        console.log(response.data);
        if (response.data.result) {
          // setLoading(false);
          //  console.log("whishlist object", response.data);
          const { whishLists
            = [] } = response.data;
          const sortWishlists = whishLists
            .sort((a, b) => a.eventDate - b.eventDate);
          setCurrWishlist({
            whishlistItemsList: response.data.whishlistItemsList,
            whishlistObject: response.data.whishlistObject,
            whishlistItemsDetailsList: response.data.whishlistItemsDetailsList,
          });
          console.log("rivky",sortWishlists[0])

          navigate(`/cart/${whishLists[whishLists.length - 1].id}`);
        } else {
          //setLoading(false);
          alert("Something went wrong");
        }
      })
      .catch((error) => {
        console.error(error);
        //setLoading(false);
        alert("Error, please try again");
      });
  };
  const goToReminders = () => {
    navigate("/reminders");
  };



  const handleLinkClick = (link) => {
    if (link.text === "אירוע חדש") {
      setMyEventsActiveTab(0);
    } else if (link.text === "האירועים שלי") {
      setMyEventsActiveTab(1);
    } else if (link.text === "היסטוריית אירועים") {
      setMyEventsActiveTab(2);
    } else if (link.text === "האירועים שהוזמנתי") {
      setMyEventsActiveTab(3);
    }
    setActiveLink(link.text);
    // console.log("handleLinkClick:", link);
    burgerToggle();
  };
  // console.log("activeLink:", activeLink);

  // const handleFilterProducts = (e, choosedCategory = null) => {
  //   if (e.target.checked) {
  //     // console.log("target checked");
  //     setFilterObject((prevObj) => {
  //       const newObj = {
  //         ...prevObj,
  //         choosedCategoriesList: [
  //           ...prevObj.choosedCategoriesList,
  //           choosedCategory,
  //         ],
  //       };
  //       return newObj;
  //     });

  //     // setChoosedCategoriesList((prevArr) => {
  //     //   const newArr = [...prevArr, choosedCategory];
  //     //   return newArr;
  //     // });

  //     const filterCheck = document.getElementById(
  //       `category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
  //     );
  //     if (filterCheck) {
  //       filterCheck.checked = true;
  //     }
  //   } else {
  //     let newCategoriesList = [...filterObject.choosedCategoriesList];
  //     filterObject.choosedCategoriesList.filter((item) => {
  //       if (
  //         item.categoryId === choosedCategory.categoryId &&
  //         item.productsId === choosedCategory.productsId
  //       ) {
  //         newCategoriesList.splice(newCategoriesList.indexOf(item), 1);
  //       }
  //       return newCategoriesList;
  //     });

  //     setFilterObject((prevObj) => {
  //       const newObj = {
  //         ...prevObj,
  //         choosedCategoriesList: [...newCategoriesList],
  //       };
  //       return newObj;
  //     });

  //     // getProductsByCategoriesList(prevCategoriesList);
  //     // setChoosedCategoriesList(prevCategoriesList);
  //     const filterCheck = document.getElementById(
  //       `category-check_${choosedCategory.categoryId}_${choosedCategory.productsId}`
  //     );
  //     if (filterCheck) {
  //       filterCheck.checked = false;
  //     }
  //   }
  //   setFilterObject((prevObj) => {
  //     const newObj = { ...prevObj, lastChangedType: "category" };
  //     return newObj;
  //   });
  // };

  function handleFilterProducts(value, state) {
    let searchParams = { ...productsFilterObject };
    if (state === true) {
      searchParams.categoryList.push(value);
    } else if (state === false) {
      searchParams.categoryList = searchParams.categoryList.filter(
        (item) => item !== value,
      );
    }
    setProductsFilterObject({ ...searchParams })
  }

  // useEffect(() => {
  //   const allCheckboxes = document.querySelectorAll(
  //     ".header-submenu__col input",
  //   );
  //   // allCheckboxes.forEach(input => input.checked = false)
  //   // if (filterObject.choosedCategoriesList.length && isFirstLoad) {
  //   const activeCheckboxes = [];
  //   if (filterObject.choosedCategoriesList?.length) {
  //     isFirstLoad = false;
  //     filterObject.choosedCategoriesList.map((item, index) => {
  //       activeCheckboxes.push(`${item.categoryId}${item.productsId}`);

  //       const choosedCategory = document.getElementById(
  //         `category-check_${item.categoryId}_${item.productsId}`,
  //       );
  //       const searchCategoryInterval = setInterval(() => {
  //         const choosedCategory = document.getElementById(
  //           `category-check_${item.categoryId}_${item.productsId}`,
  //         );
  //         // console.log('choosedCategory', item, choosedCategory);

  //         if (choosedCategory) {
  //           choosedCategory.checked = true;
  //           const headerChoosedCategory = document.getElementById(
  //             `header-category-check_${item.categoryId}_${item.productsId}`,
  //           );

  //           // console.log('headerChoosedCategory',headerChoosedCategory)
  //           const header = headerChoosedCategory.closest(".submenu-tab");
  //           // console.log('choosedCategory: ', choosedCategory, 'header: ', header)
  //           if (header) {
  //             header.setAttribute("open", true);
  //           }
  //           if (headerChoosedCategory) {
  //             headerChoosedCategory.checked = true;
  //           }

  //           clearInterval(searchCategoryInterval);
  //         }
  //       });
  //     });
  //     // const allCheckboxes = document.querySelectorAll('.submenu-tab .checkbox__input');
  //     allCheckboxes.forEach((item) => {
  //       // console.log('activeCheckboxes', activeCheckboxes, activeCheckboxes.includes(item.value))
  //       if (!activeCheckboxes.includes(item.value)) {
  //         item.checked = false;
  //       }
  //     });
  //   } else {
  //     const allHeaders = document.querySelectorAll(".submenu-tab");
  //     const allCheckboxes = document.querySelectorAll(
  //       ".submenu-tab .checkbox__input",
  //     );
  //     allCheckboxes.forEach((checkbox) => (checkbox.checked = false));
  //     allHeaders.forEach((header) => header.setAttribute("open", false));
  //   }
  // }, [filterObject]);

  // console.log('filterObject_HEader', filterObject)

  useEffect(() => {
    setUserName(currUserObject?.user?.firstName);
    return () => { };
  }, []);


  useEffect(() => {
    if (showCategMenuItem) {
      const URLParams = new URLSearchParams(window.location.search);
      const currWishlistId = URLParams?.get("wishlistId");
    }
    return () => { };
  }, [showCategMenuItem]);
  const logout = () => {
    localStorage.removeItem("currUserObject");
    navigate("/credentials");
    window.location.reload();
    isLoggedInHandler(false);
  };

  return (
    <>
      <header className="header">
        <div className="header__desktop">
          <div
            ref={subMenuRef}
            onMouseEnter={handleSubMenuMouseEnter}
            onMouseLeave={handleSubMenuMouseLeave}
            className={`header-submenu ${isSubMenuVisible ? "visible" : ""}`}
          >
            <div className="header-submenu__cont">
              <div className="header-submenu__selects"></div>
              <div className="header-submenu__bottom">
                {allCategoriesList && (
                  <>
                    <div className="header-submenu__col">
                      <div className="header-submenu__img-box">
                        <img
                          src="/img/no-people-images/category/5.png"
                          alt=""
                          className="header-submenu__img"
                        />
                        {/* <img src={present1} alt="" className="header-submenu__img" /> */}
                        {/* <h2 className="header-submenu__title">Image title</h2> */}
                      </div>
                    </div>
                    <div className="header-submenu__col">
                      {allCategoriesList.length < 3
                        ? allCategoriesList.map((category) => (
                          <details
                            key={category.mainCategory.id}
                            className="submenu-tab"
                          >
                            <summary className="submenu-tab__header">
                              <div className="submenu-tab__icon">
                                <img
                                  src={
                                    API_DOMAIN_IMG +
                                    category.mainCategory.categoryIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <h3 className="submenu-tab__title">
                                {category.mainCategory.categoryName}
                              </h3>
                              <div className="submenu-tab__arrow">
                                <svg
                                  width="64px"
                                  height="64px"
                                  viewBox="0 -4.5 20 20"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    strokeWidth={0}
                                  />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {"{"}" "{"}"}
                                    <title>arrow_down [#338]</title>{" "}
                                    <desc>Created with Sketch.</desc>
                                    {"{"}" "{"}"}
                                    <defs> </defs>
                                    {"{"}" "{"}"}
                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      {"{"}" "{"}"}
                                      <g
                                        id="Dribbble-Light-Preview"
                                        transform="translate(-220.000000, -6684.000000)"
                                        fill="#000000"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="icons"
                                          transform="translate(56.000000, 160.000000)"
                                        >
                                          {"{"}" "{"}"}
                                          <path
                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                            id="arrow_down-[#338]"
                                          >
                                            {"{"}" "{"}"}
                                          </path>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                    {"{"}" "{"}"}
                                  </g>
                                </svg>
                              </div>
                            </summary>
                            <div className="submenu-tab__body">
                              <div className="single-category-type__dropdown-list">
                                {category.productCategory.map(
                                  (subcategory) => (
                                    // <li
                                    // key={subcategory.id}
                                    //   className={`single-category-type__dropdown-list-item active`}
                                    // >
                                    //   <p>{subcategory.niceName}</p>
                                    // </li>
                                    <div
                                      className="checkbox"
                                      key={subcategory.id}
                                    >
                                      <input
                                        id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__input"
                                        type="checkbox"
                                        defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                        checked={productsFilterObject.categoryList.includes(
                                          String(subcategory.id),
                                        )}
                                        onChange={(e) =>
                                          // handleFilterProducts(e, {
                                          //   categoryId: String(
                                          //     category.mainCategory.id
                                          //   ),
                                          //   productsId: String(
                                          //     subcategory.id
                                          //   ),
                                          // })
                                          handleFilterProducts(
                                            String(subcategory.id),
                                            e.target.checked,
                                          )
                                        }
                                        name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                      />
                                      <label
                                        htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__label"
                                      >
                                        <span className="checkbox__text">
                                          {subcategory.niceName}
                                        </span>
                                      </label>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </details>
                        ))
                        : allCategoriesList.slice(0, 3).map((category) => (
                          <details
                            key={category.mainCategory.id}
                            className="submenu-tab"
                          >
                            <summary className="submenu-tab__header">
                              <div className="submenu-tab__icon">
                                <img
                                  src={
                                    API_DOMAIN_IMG +
                                    category.mainCategory.categoryIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <h3 className="submenu-tab__title">
                                {category.mainCategory.categoryName}
                              </h3>
                              <div className="submenu-tab__arrow">
                                <svg
                                  width="64px"
                                  height="64px"
                                  viewBox="0 -4.5 20 20"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    strokeWidth={0}
                                  />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {"{"}" "{"}"}
                                    <title>arrow_down [#338]</title>{" "}
                                    <desc>Created with Sketch.</desc>
                                    {"{"}" "{"}"}
                                    <defs> </defs>
                                    {"{"}" "{"}"}
                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      {"{"}" "{"}"}
                                      <g
                                        id="Dribbble-Light-Preview"
                                        transform="translate(-220.000000, -6684.000000)"
                                        fill="#000000"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="icons"
                                          transform="translate(56.000000, 160.000000)"
                                        >
                                          {"{"}" "{"}"}
                                          <path
                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                            id="arrow_down-[#338]"
                                          >
                                            {"{"}" "{"}"}
                                          </path>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                    {"{"}" "{"}"}
                                  </g>
                                </svg>
                              </div>
                            </summary>
                            <div className="submenu-tab__body">
                              <div className="single-category-type__dropdown-list">
                                {category.productCategory.map(
                                  (subcategory) => (
                                    // <li
                                    // key={subcategory.id}
                                    //   className={`single-category-type__dropdown-list-item active`}
                                    // >
                                    //   <p>{subcategory.niceName}</p>
                                    // </li>
                                    <div
                                      className="checkbox"
                                      key={subcategory.id}
                                    >
                                      <input
                                        id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__input"
                                        type="checkbox"
                                        checked={productsFilterObject.categoryList.includes(
                                          String(subcategory.id),
                                        )}
                                        defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                        onChange={(e) =>
                                          // handleFilterProducts(e, {
                                          //   categoryId: String(
                                          //     category.mainCategory.id
                                          //   ),
                                          //   productsId: String(
                                          //     subcategory.id
                                          //   ),
                                          // })
                                          handleFilterProducts(
                                            String(subcategory.id),
                                            e.target.checked,
                                          )
                                        }
                                        name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                      />
                                      <label
                                        htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__label"
                                      >
                                        <span className="checkbox__text">
                                          {subcategory.niceName}
                                        </span>
                                      </label>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </details>
                        ))}
                    </div>
                    <div className="header-submenu__col">
                      {allCategoriesList.length < 6 &&
                        allCategoriesList.length > 3
                        ? allCategoriesList.map((category) => (
                          <details
                            key={category.mainCategory.id}
                            className="submenu-tab"
                          >
                            <summary className="submenu-tab__header">
                              <div className="submenu-tab__icon">
                                <img
                                  src={
                                    API_DOMAIN_IMG +
                                    category.mainCategory.categoryIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <h3 className="submenu-tab__title">
                                {category.mainCategory.categoryName}
                              </h3>
                              <div className="submenu-tab__arrow">
                                <svg
                                  width="64px"
                                  height="64px"
                                  viewBox="0 -4.5 20 20"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    strokeWidth={0}
                                  />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {"{"}" "{"}"}
                                    <title>arrow_down [#338]</title>{" "}
                                    <desc>Created with Sketch.</desc>
                                    {"{"}" "{"}"}
                                    <defs> </defs>
                                    {"{"}" "{"}"}
                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      {"{"}" "{"}"}
                                      <g
                                        id="Dribbble-Light-Preview"
                                        transform="translate(-220.000000, -6684.000000)"
                                        fill="#000000"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="icons"
                                          transform="translate(56.000000, 160.000000)"
                                        >
                                          {"{"}" "{"}"}
                                          <path
                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                            id="arrow_down-[#338]"
                                          >
                                            {"{"}" "{"}"}
                                          </path>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                    {"{"}" "{"}"}
                                  </g>
                                </svg>
                              </div>
                            </summary>
                            <div className="submenu-tab__body">
                              <div className="single-category-type__dropdown-list">
                                {category.productCategory.map(
                                  (subcategory) => (
                                    // <li
                                    // key={subcategory.id}
                                    //   className={`single-category-type__dropdown-list-item active`}
                                    // >
                                    //   <p>{subcategory.niceName}</p>
                                    // </li>
                                    <div
                                      className="checkbox"
                                      key={subcategory.id}
                                    >
                                      <input
                                        id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__input"
                                        type="checkbox"
                                        checked={productsFilterObject.categoryList.includes(
                                          String(subcategory.id),
                                        )}
                                        defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                        onChange={(e) =>
                                          // handleFilterProducts(e, {
                                          //   categoryId: String(
                                          //     category.mainCategory.id
                                          //   ),
                                          //   productsId: String(
                                          //     subcategory.id
                                          //   ),
                                          // })
                                          handleFilterProducts(
                                            String(subcategory.id),
                                            e.target.checked,
                                          )
                                        }
                                        name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                      />
                                      <label
                                        htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__label"
                                      >
                                        <span className="checkbox__text">
                                          {subcategory.niceName}
                                        </span>
                                      </label>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </details>
                        ))
                        : allCategoriesList.length >= 6
                          ? allCategoriesList.slice(3, 6).map((category) => (
                            <details
                              key={category.mainCategory.id}
                              className="submenu-tab"
                            >
                              <summary className="submenu-tab__header">
                                <div className="submenu-tab__icon">
                                  <img
                                    src={
                                      API_DOMAIN_IMG +
                                      category.mainCategory.categoryIcon
                                    }
                                    alt=""
                                  />
                                </div>
                                <h3 className="submenu-tab__title">
                                  {category.mainCategory.categoryName}
                                </h3>
                                <div className="submenu-tab__arrow">
                                  <svg
                                    width="64px"
                                    height="64px"
                                    viewBox="0 -4.5 20 20"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    fill="#000000"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth={0}
                                    />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      {"{"}" "{"}"}
                                      <title>arrow_down [#338]</title>{" "}
                                      <desc>Created with Sketch.</desc>
                                      {"{"}" "{"}"}
                                      <defs> </defs>
                                      {"{"}" "{"}"}
                                      <g
                                        id="Page-1"
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="Dribbble-Light-Preview"
                                          transform="translate(-220.000000, -6684.000000)"
                                          fill="#000000"
                                        >
                                          {"{"}" "{"}"}
                                          <g
                                            id="icons"
                                            transform="translate(56.000000, 160.000000)"
                                          >
                                            {"{"}" "{"}"}
                                            <path
                                              d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                              id="arrow_down-[#338]"
                                            >
                                              {"{"}" "{"}"}
                                            </path>
                                            {"{"}" "{"}"}
                                          </g>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                  </svg>
                                </div>
                              </summary>
                              <div className="submenu-tab__body">
                                <div className="single-category-type__dropdown-list">
                                  {category.productCategory.map(
                                    (subcategory) => (
                                      // <li
                                      // key={subcategory.id}
                                      //   className={`single-category-type__dropdown-list-item active`}
                                      // >
                                      //   <p>{subcategory.niceName}</p>
                                      // </li>
                                      <div
                                        className="checkbox"
                                        key={subcategory.id}
                                      >
                                        <input
                                          id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                          className="checkbox__input"
                                          type="checkbox"
                                          checked={productsFilterObject.categoryList.includes(
                                            String(subcategory.id),
                                          )}
                                          defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                          onChange={(e) =>
                                            // handleFilterProducts(e, {
                                            //   categoryId: String(
                                            //     category.mainCategory.id
                                            //   ),
                                            //   productsId: String(
                                            //     subcategory.id
                                            //   ),
                                            // })
                                            handleFilterProducts(
                                              String(subcategory.id),
                                              e.target.checked,
                                            )
                                          }
                                          name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        />
                                        <label
                                          htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                          className="checkbox__label"
                                        >
                                          <span className="checkbox__text">
                                            {subcategory.niceName}
                                          </span>
                                        </label>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </details>
                          ))
                          : ""}
                    </div>
                    <div className="header-submenu__col">
                      {allCategoriesList.length < 9 &&
                        allCategoriesList.length > 6
                        ? allCategoriesList.map((category) => (
                          <details
                            key={category.mainCategory.id}
                            className="submenu-tab"
                          >
                            <summary className="submenu-tab__header">
                              <div className="submenu-tab__icon">
                                <img
                                  src={
                                    API_DOMAIN_IMG +
                                    category.mainCategory.categoryIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <h3 className="submenu-tab__title">
                                {category.mainCategory.categoryName}
                              </h3>
                              <div className="submenu-tab__arrow">
                                <svg
                                  width="64px"
                                  height="64px"
                                  viewBox="0 -4.5 20 20"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  fill="#000000"
                                >
                                  <g
                                    id="SVGRepo_bgCarrier"
                                    strokeWidth={0}
                                  />
                                  <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <g id="SVGRepo_iconCarrier">
                                    {"{"}" "{"}"}
                                    <title>arrow_down [#338]</title>{" "}
                                    <desc>Created with Sketch.</desc>
                                    {"{"}" "{"}"}
                                    <defs> </defs>
                                    {"{"}" "{"}"}
                                    <g
                                      id="Page-1"
                                      stroke="none"
                                      strokeWidth={1}
                                      fill="none"
                                      fillRule="evenodd"
                                    >
                                      {"{"}" "{"}"}
                                      <g
                                        id="Dribbble-Light-Preview"
                                        transform="translate(-220.000000, -6684.000000)"
                                        fill="#000000"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="icons"
                                          transform="translate(56.000000, 160.000000)"
                                        >
                                          {"{"}" "{"}"}
                                          <path
                                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                            id="arrow_down-[#338]"
                                          >
                                            {"{"}" "{"}"}
                                          </path>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                    {"{"}" "{"}"}
                                  </g>
                                </svg>
                              </div>
                            </summary>
                            <div className="submenu-tab__body">
                              <div className="single-category-type__dropdown-list">
                                {category.productCategory.map(
                                  (subcategory) => (
                                    // <li
                                    // key={subcategory.id}
                                    //   className={`single-category-type__dropdown-list-item active`}
                                    // >
                                    //   <p>{subcategory.niceName}</p>
                                    // </li>
                                    <div
                                      className="checkbox"
                                      key={subcategory.id}
                                    >
                                      <input
                                        id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__input"
                                        type="checkbox"
                                        checked={productsFilterObject.categoryList.includes(
                                          String(subcategory.id),
                                        )}
                                        defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                        onChange={(e) =>
                                          // handleFilterProducts(e, {
                                          //   categoryId: String(
                                          //     category.mainCategory.id
                                          //   ),
                                          //   productsId: String(
                                          //     subcategory.id
                                          //   ),
                                          // })
                                          handleFilterProducts(
                                            String(subcategory.id),
                                            e.target.checked,
                                          )
                                        }
                                        name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                      />
                                      <label
                                        htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        className="checkbox__label"
                                      >
                                        <span className="checkbox__text">
                                          {subcategory.niceName}
                                        </span>
                                      </label>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          </details>
                        ))
                        : allCategoriesList.length >= 9
                          ? allCategoriesList.slice(6).map((category) => (
                            <details
                              key={category.mainCategory.id}
                              className="submenu-tab"
                            >
                              <summary className="submenu-tab__header">
                                <div className="submenu-tab__icon">
                                  <img
                                    src={
                                      API_DOMAIN_IMG +
                                      category.mainCategory.categoryIcon
                                    }
                                    alt=""
                                  />
                                </div>
                                <h3 className="submenu-tab__title">
                                  {category.mainCategory.categoryName}
                                </h3>
                                <div className="submenu-tab__arrow">
                                  <svg
                                    width="64px"
                                    height="64px"
                                    viewBox="0 -4.5 20 20"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    fill="#000000"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth={0}
                                    />
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <g id="SVGRepo_iconCarrier">
                                      {"{"}" "{"}"}
                                      <title>arrow_down [#338]</title>{" "}
                                      <desc>Created with Sketch.</desc>
                                      {"{"}" "{"}"}
                                      <defs> </defs>
                                      {"{"}" "{"}"}
                                      <g
                                        id="Page-1"
                                        stroke="none"
                                        strokeWidth={1}
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        {"{"}" "{"}"}
                                        <g
                                          id="Dribbble-Light-Preview"
                                          transform="translate(-220.000000, -6684.000000)"
                                          fill="#000000"
                                        >
                                          {"{"}" "{"}"}
                                          <g
                                            id="icons"
                                            transform="translate(56.000000, 160.000000)"
                                          >
                                            {"{"}" "{"}"}
                                            <path
                                              d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                                              id="arrow_down-[#338]"
                                            >
                                              {"{"}" "{"}"}
                                            </path>
                                            {"{"}" "{"}"}
                                          </g>
                                          {"{"}" "{"}"}
                                        </g>
                                        {"{"}" "{"}"}
                                      </g>
                                      {"{"}" "{"}"}
                                    </g>
                                  </svg>
                                </div>
                              </summary>
                              <div className="submenu-tab__body">
                                <div className="single-category-type__dropdown-list">
                                  {category.productCategory.map(
                                    (subcategory) => (
                                      // <li
                                      // key={subcategory.id}
                                      //   className={`single-category-type__dropdown-list-item active`}
                                      // >
                                      //   <p>{subcategory.niceName}</p>
                                      // </li>
                                      <div
                                        className="checkbox"
                                        key={subcategory.id}
                                      >
                                        <input
                                          id={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                          className="checkbox__input"
                                          type="checkbox"
                                          checked={productsFilterObject.categoryList.includes(
                                            String(subcategory.id),
                                          )}
                                          defaultValue={`${category.mainCategory.id}${subcategory.id}`}
                                          onChange={(e) =>
                                            // handleFilterProducts(e, {
                                            //   categoryId: String(
                                            //     category.mainCategory.id
                                            //   ),
                                            //   productsId: String(
                                            //     subcategory.id
                                            //   ),
                                            // })
                                            handleFilterProducts(
                                              String(subcategory.id),
                                              e.target.checked,
                                            )
                                          }
                                          name={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                        />
                                        <label
                                          htmlFor={`header-category-check_${category.mainCategory.id}_${subcategory.id}`}
                                          className="checkbox__label"
                                        >
                                          <span className="checkbox__text">
                                            {subcategory.niceName}
                                          </span>
                                        </label>
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            </details>
                          ))
                          : ""}
                    </div>
                    <div className="header-submenu__col">
                      <div className="header-submenu__img-box">
                        <img
                          src="/img/no-people-images/category/3.png"
                          alt=""
                          className="header-submenu__img"
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* <NavLink
                  onClick={() =>
                    setFilterObject((prevObj) => {
                      const newObj = {
                        ...prevObj,
                        lastChangedType: "category",
                      };
                      return newObj;
                    })
                  }
                  // to={`/products-by-categories?wishlistId=${currWishlistId}`} className="btn btn-categories-search">
                  to={`/${redirectLink}/${filterObject?.choosedCategoriesList[0]?.categoryId}/${filterObject?.choosedCategoriesList[0]?.productsId}/?wishlistId=${currWishlistId}${productsFilterObject.categoryList.length > 0 ? "&category=" + productsFilterObject.categoryList : ""}`}
                  className="btn btn-categories-search"
                >
                  Search
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="header__top top-header">
            <div className="top-header__container">
              <div className="top-header__body">
                <div className="top-header__search">
                  <form
                    action="#"
                    className="top-header__search-form search-form"
                  >
                    <label
                      className="search-form__input-wrapper"
                      htmlFor="top-header__search-input"
                    >
                      <input
                        autoComplete="off"
                        id="top-header__search-input"
                        type="text"
                        name="search"
                        placeholder="איזה מתנה תרצו לחפש"
                        className="search-input"
                      />
                      <button
                        type="submit"
                        className="search-button"
                      >
                        <svg
                          id="loupe"
                          xmlns="http://www.w3.org/2000/svg"
                          width="10.758"
                          height="11.504"
                          viewBox="0 0 10.758 11.504"
                        >
                          <g
                            id="Сгруппировать_33"
                            data-name="Сгруппировать 33"
                          >
                            <g
                              id="Сгруппировать_32"
                              data-name="Сгруппировать 32"
                            >
                              <path
                                id="Контур_117"
                                data-name="Контур 117"
                                d="M4.863,0A4.863,4.863,0,1,0,9.726,4.863,4.869,4.869,0,0,0,4.863,0Zm0,8.828A3.965,3.965,0,1,1,8.828,4.863,3.97,3.97,0,0,1,4.863,8.828Z"
                                fill="#707070"
                              />
                            </g>
                          </g>
                          <g
                            id="Сгруппировать_35"
                            data-name="Сгруппировать 35"
                            transform="translate(7.463 8.209)"
                          >
                            <g
                              id="Сгруппировать_34"
                              data-name="Сгруппировать 34"
                            >
                              <path
                                id="Контур_118"
                                data-name="Контур 118"
                                d="M354.216,353.613l-2.443-2.443a.426.426,0,0,0-.6.6l2.443,2.443a.426.426,0,0,0,.6-.6Z"
                                transform="translate(-351.046 -351.046)"
                                fill="#707070"
                              />
                            </g>
                          </g>
                        </svg>
                      </button>
                    </label>
                  </form>
                </div>

                <div className="top-header__menu-col">
                  <nav className="top-header__menu menu-top-header">
                    <ul className="menu-top-header__list">
                      {isLoggedIn && (
                        <>
                          <li className="menu-top-header__list-item">
                            <NavLink
                              to="/"
                              className="menu-top-header__link"
                            >
                              <div className="image">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25.516"
                                  height="25.445"
                                  viewBox="0 0 25.516 25.445"
                                >
                                  <path
                                    id="home_1_"
                                    data-name="home (1)"
                                    d="M23.591,11.625s0-1.082,0-1.082L13.7.653a2.231,2.231,0,0,0-3.157,0L.658,10.538l-.01.01a2.231,2.231,0,0,0,1.485,3.8q.034,0,.069,0H2.6v7.278a2.615,2.615,0,0,0,2.612,2.612H9.078a.71.71,0,0,0,.71-.71V17.828a1.193,1.193,0,0,1,1.192-1.192h2.282a1.193,1.193,0,0,1,1.192,1.192v5.706a.71.71,0,0,0,.71.71h3.869a2.615,2.615,0,0,0,2.612-2.612V14.354h.366A2.218,2.218,0,0,0,23.59,13.7c.869-.87.87-1.2,0-2.075Zm0-1.081"
                                    transform="translate(0.771 0.601)"
                                    fill="none"
                                    stroke="#707070"
                                    strokeWidth="1.2"
                                  />
                                </svg>
                              </div>
                              <p className="text">ראשי</p>
                            </NavLink>
                          </li>
                          <li className="menu-top-header__list-item">
                            <NavLink
                              to="/edit-profile"
                              className="menu-top-header__link"
                            >
                              <div className="image">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24.067"
                                  height="24.067"
                                  viewBox="0 0 24.067 24.067"
                                >
                                  <g
                                    id="Компонент_165_29"
                                    data-name="Компонент 165 – 29"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Объединение_74"
                                      data-name="Объединение 74"
                                      d="M0,23.067V20.184c0-3.172,5.19-5.767,11.533-5.767s11.533,2.6,11.533,5.767v2.883Zm5.766-17.3a5.767,5.767,0,1,1,5.767,5.766A5.767,5.767,0,0,1,5.766,5.767Z"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <p className="text">אזור אישי</p>
                            </NavLink>
                          </li>
                          <li className="menu-top-header__list-item">
                            <NavLink
                           //   onClick={() => goToCartByWishlist()}
                              //       to="/cart-purchased"
                              className="menu-top-header__link"
                            >
                              <div
                                className="image"
                              // data-count="2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19.846"
                                  height="24.798"
                                  viewBox="0 0 19.846 24.798"
                                >
                                  <g
                                    id="Сгруппировать_74057"
                                    data-name="Сгруппировать 74057"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="heart"
                                      d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                                      transform="translate(-25.26 -15.21)"
                                      fill="#ec1b53"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <p className="text">סל המתנות שלי</p>
                            </NavLink>
                          </li>
                          <li className="menu-top-header__list-item">
                            <NavLink
                              to="/my-bank"
                              className="menu-top-header__link"
                            >
                              <div
                                className="image"
                              // data-count="2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="19.846"
                                  height="24.798"
                                  viewBox="0 0 19.846 24.798"
                                >
                                  <g
                                    id="Сгруппировать_74057"
                                    data-name="Сгруппировать 74057"
                                    transform="translate(0.5 0.5)"
                                  >
                                    <path
                                      id="Artboard_2"
                                      data-name="Artboard 2"
                                      d="M84.786,18.61a7.137,7.137,0,0,1,1.2.467,2.838,2.838,0,0,1,1.406,2.495c.014.656,0,1.314,0,2H90a1.672,1.672,0,0,1,1.764,1.55q.562,4.176,1.116,8.353c.31,2.332.627,4.663.93,7a1.654,1.654,0,0,1-1.319,1.9.426.426,0,0,0-.082.038H76.418A1.88,1.88,0,0,1,75.31,41.7a1.6,1.6,0,0,1-.3-1.14q.439-3.333.886-6.671.4-3.047.81-6.1c.124-.93.243-1.859.372-2.784a1.661,1.661,0,0,1,1.612-1.432c.821-.015,1.641,0,2.463-.006.292-.1.292-.193.292-.282a20.1,20.1,0,0,1,.043-2.111,2.906,2.906,0,0,1,2.455-2.53.644.644,0,0,0,.105-.04Z"
                                      transform="translate(-74.992 -18.61)"
                                      fill="none"
                                      stroke="#707070"
                                      strokeWidth="1"
                                    />
                                    <path
                                      id="heart"
                                      d="M30.6,30.513l4.251,4.3,4.566-4.615a3.06,3.06,0,0,0,0-4.3,2.983,2.983,0,0,0-4.25,0l-.315.318L34.54,25.9a2.983,2.983,0,0,0-4.25,0,3.06,3.06,0,0,0,0,4.3l.313.316Z"
                                      transform="translate(-25.26 -15.21)"
                                      fill="#ffaf00"
                                    />
                                  </g>
                                </svg>
                              </div>
                              <p className="text">רכישת מתנות</p>
                            </NavLink>
                          </li>
                        </>
                      )}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          <div className="header__container">
            <NavLink
              to="/"
              className="header__logo"
            >
              <img
                src={logoImg}
                alt="Logo"
              />
            </NavLink>

            <div className="header__menu menu">
              <nav className="menu__body">
                <ul className="menu__list">
                  {showCategMenuItem && (
                    <li
                      className="menu__item"
                      ref={linkSubmenuRef}
                      onMouseEnter={handleLinkMouseEnter}
                      onMouseLeave={handleLinkMouseLeave}
                    >
                      <button className="menu__link">קטגוריות</button>
                    </li>
                  )}
                  <li className="menu__item">
                    <div className="menu__link dropdown">
                      <p>איך זה עובד?</p>
                      <div className="dropdown__body">
                        <NavLink
                          to="/explaining-steps"
                          className="dropdown__link"
                        >
                          מזמינים
                        </NavLink>
                        <NavLink
                          to="/explaining-steps-2"
                          className="dropdown__link"
                        >
                          מוזמנים
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li
                    className="menu__item"
                  // ref={linkSubmenuRef}
                  // onMouseEnter={handleLinkMouseEnter}
                  // onMouseLeave={handleLinkMouseLeave}
                  >
                    <NavLink
                      to="/my-events"
                      onClick={() => setMyEventsActiveTab(0)}
                      className="menu__link"
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="29.959"
                          height="22.842"
                          viewBox="0 0 29.959 22.842"
                        >
                          <g
                            id="Сгруппировать_74063"
                            data-name="Сгруппировать 74063"
                            transform="translate(-14 0.5)"
                          >
                            <g
                              id="Artboard_3"
                              data-name="Artboard 3"
                              transform="translate(22.214 0)"
                            >
                              <path
                                id="Контур_38424"
                                data-name="Контур 38424"
                                d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                                transform="translate(-24.307 -20.873)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38425"
                                data-name="Контур 38425"
                                d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                                transform="translate(-20.77 -14.613)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38426"
                                data-name="Контур 38426"
                                d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                                transform="translate(-14.469 -14.389)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <line
                                id="Линия_526"
                                data-name="Линия 526"
                                y2="4.793"
                                transform="translate(9.839 13.419)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <line
                                id="Линия_527"
                                data-name="Линия 527"
                                y2="8.147"
                                transform="translate(7.443 11.023)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <line
                                id="Линия_528"
                                data-name="Линия 528"
                                y2="11.684"
                                transform="translate(5.047 8.445)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <line
                                id="Линия_529"
                                data-name="Линия 529"
                                y2="7.055"
                                transform="translate(2.651 14.033)"
                                fill="none"
                                stroke="#707070"
                                strokeMiterlimit="10"
                                strokeWidth="1"
                              />
                              <circle
                                id="Эллипс_414"
                                data-name="Эллипс 414"
                                cx="1.438"
                                cy="1.438"
                                r="1.438"
                                transform="translate(10.319)"
                                fill="none"
                                stroke="#707070"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38427"
                                data-name="Контур 38427"
                                d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                                transform="translate(-27.244 -17.922)"
                                fill="none"
                                stroke="#707070"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38428"
                                data-name="Контур 38428"
                                d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                                transform="translate(-31.988 -10.775)"
                                fill="none"
                                stroke="#707070"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38429"
                                data-name="Контур 38429"
                                d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                                transform="translate(-20.474 -9.487)"
                                fill="none"
                                stroke="#707070"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                              <path
                                id="Контур_38430"
                                data-name="Контур 38430"
                                d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                                transform="translate(-28.181 -7.104)"
                                fill="none"
                                stroke="#707070"
                                strokeLinejoin="round"
                                strokeWidth="1"
                              />
                            </g>
                            <g
                              id="Сгруппировать_1710"
                              data-name="Сгруппировать 1710"
                              transform="translate(14 2.322)"
                            >
                              <g
                                id="Контур_10101"
                                data-name="Контур 10101"
                                transform="translate(0 1.369)"
                                fill="#3c9e1c"
                              >
                                <path
                                  d="M 6.844831466674805 13.18966102600098 C 3.3462815284729 13.18966102600098 0.5000014901161194 10.34338188171387 0.5000014901161194 6.844831466674805 C 0.5000014901161194 3.3462815284729 3.3462815284729 0.5000014901161194 6.844831466674805 0.5000014901161194 C 10.34338188171387 0.5000014901161194 13.18966102600098 3.3462815284729 13.18966102600098 6.844831466674805 C 13.18966102600098 10.34338188171387 10.34338188171387 13.18966102600098 6.844831466674805 13.18966102600098 Z"
                                  stroke="none"
                                />
                                <path
                                  d="M 6.844831466674805 1.000001907348633 C 3.621981620788574 1.000001907348633 1.000001907348633 3.621981620788574 1.000001907348633 6.844831466674805 C 1.000001907348633 10.06768131256104 3.621981620788574 12.68966102600098 6.844831466674805 12.68966102600098 C 10.06768131256104 12.68966102600098 12.68966102600098 10.06768131256104 12.68966102600098 6.844831466674805 C 12.68966102600098 3.621981620788574 10.06768131256104 1.000001907348633 6.844831466674805 1.000001907348633 M 6.844831466674805 1.9073486328125e-06 C 10.62513160705566 1.9073486328125e-06 13.68966102600098 3.064531326293945 13.68966102600098 6.844831466674805 C 13.68966102600098 10.62513160705566 10.62513160705566 13.68966102600098 6.844831466674805 13.68966102600098 C 3.064531326293945 13.68966102600098 1.9073486328125e-06 10.62513160705566 1.9073486328125e-06 6.844831466674805 C 1.9073486328125e-06 3.064531326293945 3.064531326293945 1.9073486328125e-06 6.844831466674805 1.9073486328125e-06 Z"
                                  stroke="none"
                                  fill="#fff"
                                />
                              </g>
                              <text
                                id="_"
                                data-name="+"
                                transform="translate(6.845 12.059)"
                                fill="#fff"
                                fontSize="11"
                                fontFamily="Assistant-SemiBold, Assistant"
                                fontWeight="600"
                              >
                                <tspan
                                  x="2.783"
                                  y="0"
                                >
                                  +
                                </tspan>
                              </text>
                            </g>
                          </g>
                        </svg>
                      </span>
                      אירוע חדש
                    </NavLink>
                  </li>
                  {isLoggedIn ? (
                    <li className="menu__item">
                      <div className="menu__link dropdown">
                        <p
                          className="menu__link"
                          onClick={handleMyEventLinkClick}
                        >
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.27"
                              height="22.842"
                              viewBox="0 0 22.27 22.842"
                            >
                              <g
                                id="Artboard_3"
                                data-name="Artboard 3"
                                transform="translate(0.525 0.5)"
                              >
                                <path
                                  id="Контур_38424"
                                  data-name="Контур 38424"
                                  d="M33.36,33.64c2.435,2.435,5.013,3.8,5.761,3.048"
                                  transform="translate(-24.307 -20.873)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38425"
                                  data-name="Контур 38425"
                                  d="M29.823,27.381c-2.435-2.435-3.8-5.013-3.048-5.761"
                                  transform="translate(-20.77 -14.613)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38426"
                                  data-name="Контур 38426"
                                  d="M14.561,34.867a1.017,1.017,0,0,0,.153,1.1,1.035,1.035,0,0,0,1.1.153s12.331-4.769,13.136-5.574l.34-.34c.748-.748-.618-3.326-3.048-5.761s-5.013-3.8-5.761-3.048l-.34.34c-.805.805-5.574,13.136-5.574,13.136Z"
                                  transform="translate(-14.469 -14.389)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <line
                                  id="Линия_526"
                                  data-name="Линия 526"
                                  y2="4.793"
                                  transform="translate(9.839 13.419)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <line
                                  id="Линия_527"
                                  data-name="Линия 527"
                                  y2="8.147"
                                  transform="translate(7.443 11.023)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <line
                                  id="Линия_528"
                                  data-name="Линия 528"
                                  y2="11.684"
                                  transform="translate(5.047 8.445)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <line
                                  id="Линия_529"
                                  data-name="Линия 529"
                                  y2="7.055"
                                  transform="translate(2.651 14.033)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeMiterlimit="10"
                                  strokeWidth="1"
                                />
                                <circle
                                  id="Эллипс_414"
                                  data-name="Эллипс 414"
                                  cx="1.438"
                                  cy="1.438"
                                  r="1.438"
                                  transform="translate(10.319)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38427"
                                  data-name="Контур 38427"
                                  d="M39,31.341c1.86-2.305,4.793-4.332,7.189-2.876,2.022,1.227,1.236,3.134-.038,3.336a1.538,1.538,0,0,1-1.879-1.419"
                                  transform="translate(-27.244 -17.922)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38428"
                                  data-name="Контур 38428"
                                  d="M52.61,14.777l-.484,1.673,1.107,1.38-1.869.129-.935,1.361-.719-1.548-1.6-.637,1.371-1.016.058-1.869,1.419,1.064Z"
                                  transform="translate(-31.988 -10.775)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38429"
                                  data-name="Контур 38429"
                                  d="M28.607,18.684c1.265-2.411,2.061-6.158-.211-6.8-1.419-.4-2.4.359-2.4,1.438a1.2,1.2,0,0,0,1.917.959"
                                  transform="translate(-20.474 -9.487)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                                <path
                                  id="Контур_38430"
                                  data-name="Контур 38430"
                                  d="M42.674,7.2A4.861,4.861,0,0,1,40.8,13.814"
                                  transform="translate(-28.181 -7.104)"
                                  fill="none"
                                  stroke="#707070"
                                  strokeLinejoin="round"
                                  strokeWidth="1"
                                />
                              </g>
                            </svg>
                          </span>
                          האירועים שלי
                        </p>

                        <div className="dropdown__body">
                          <NavLink
                            to="/my-events"
                            onClick={() => setMyEventsActiveTab(1)}
                            className="dropdown__link"
                          >
                            האירועים שלי
                          </NavLink>
                          <NavLink
                            to="/my-events"
                            onClick={() => setMyEventsActiveTab(2)}
                            className="dropdown__link"
                          >
                            היסטוריית אירועים
                          </NavLink>
                          <NavLink
                            to="/my-events"
                            onClick={() => setMyEventsActiveTab(3)}
                            className="dropdown__link"
                          >
                            האירועים שהוזמנתי
                          </NavLink>
                        </div>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}

                  {isLoggedIn ? (
                    <li className="menu__item">
                      <NavLink
                        to="/my-bank"
                        className="menu__link"
                      >
                        <span className="icon">
                          <svg
                            id="Artboard_1"
                            data-name="Artboard 1"
                            xmlns="http://www.w3.org/2000/svg"
                            width="23.901"
                            height="24.498"
                            viewBox="0 0 23.901 24.498"
                          >
                            <path
                              id="Контур_10114"
                              data-name="Контур 10114"
                              d="M15.336,58.018h2.227v-.891H15.336a.445.445,0,0,1-.445-.445V51.336a.445.445,0,0,1,.445-.445h2.227V50H15.336A1.336,1.336,0,0,0,14,51.336v5.345A1.336,1.336,0,0,0,15.336,58.018Z"
                              transform="translate(6.338 -33.519)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10115"
                              data-name="Контур 10115"
                              d="M16,54h.891v.891H16V54Z"
                              transform="translate(6.12 -35.737)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10116"
                              data-name="Контур 10116"
                              d="M35.254,49.454h.891v-.891h-.891a.445.445,0,0,1-.347-.169C34.131,47.374,32,45,29.018,45H22.782a1.782,1.782,0,1,0,0,3.563h5.345v-.891H22.782a.891.891,0,1,1,0-1.782h6.236c2.579,0,4.485,2.129,5.189,3.042A1.336,1.336,0,0,0,35.254,49.454Z"
                              transform="translate(-15.361 -30.746)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10117"
                              data-name="Контур 10117"
                              d="M33.9,52.245h7.884v-.891H33.9a3.819,3.819,0,0,1-1.2-.2l-4.1-1.381a2.389,2.389,0,0,1-.848-.5l-5.7-5.207a.7.7,0,0,1-.187-.733.614.614,0,0,1,.566-.443.6.6,0,0,1,.3.085l6.018,3.7.407-.775-6.039-3.7a1.3,1.3,0,0,0-.689-.2,1.435,1.435,0,0,0-1.34,1.027,1.648,1.648,0,0,0,.432,1.726l5.7,5.207a3.164,3.164,0,0,0,1.116.668l4.1,1.381A4.554,4.554,0,0,0,33.9,52.245Z"
                              transform="translate(-21 -29.083)"
                              fill="#707070"
                            />
                            <rect
                              id="Прямоугольник_2304"
                              data-name="Прямоугольник 2304"
                              width="1.4"
                              height="2.8"
                              transform="translate(7.107 2.8)"
                              fill="#707070"
                            />
                            <rect
                              id="Прямоугольник_2305"
                              data-name="Прямоугольник 2305"
                              width="0.7"
                              height="2.8"
                              transform="translate(9.207)"
                              fill="#707070"
                            />
                            <rect
                              id="Прямоугольник_2306"
                              data-name="Прямоугольник 2306"
                              width="0.7"
                              height="2.8"
                              transform="translate(13.407 8.399)"
                              fill="#707070"
                            />
                            <rect
                              id="Прямоугольник_2307"
                              data-name="Прямоугольник 2307"
                              width="1.4"
                              height="1.4"
                              transform="translate(14.807 11.899)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10118"
                              data-name="Контур 10118"
                              d="M33.991,19.771a1.782,1.782,0,0,0,1.287-.494,1.987,1.987,0,0,0,.414-1.938,4.789,4.789,0,0,0-3.278-3.278,1.822,1.822,0,0,0-2.352,2.352,4.789,4.789,0,0,0,3.278,3.278,2.82,2.82,0,0,0,.65.08Zm-2.227-4.9a2.058,2.058,0,0,1,.445.053,3.959,3.959,0,0,1,2.619,2.619,1.212,1.212,0,0,1-.178,1.1,1.212,1.212,0,0,1-1.1.178,3.959,3.959,0,0,1-2.619-2.619,1.212,1.212,0,0,1,.178-1.1.926.926,0,0,1,.659-.232Z"
                              transform="translate(-18.31 -13.535)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10119"
                              data-name="Контур 10119"
                              d="M35.088,21.742a1.782,1.782,0,0,0,1.3-.494l1.336-1.336-.628-.628L35.756,20.62c-.445.445-1.7.241-2.744-.806-.94-.94-1.336-2.227-.8-2.739l1.336-1.336-.628-.628-1.336,1.336c-.891.891-.543,2.673.8,4.009a4.04,4.04,0,0,0,2.7,1.287Z"
                              transform="translate(-20.664 -14.17)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10120"
                              data-name="Контур 10120"
                              d="M49.745,33.234a3.4,3.4,0,0,0,2.717-2.169,3.924,3.924,0,0,0,.316-2.882,1.755,1.755,0,0,0-.891-1.033c-1.114-.53-2.61.356-3.408,2.018a3.924,3.924,0,0,0-.316,2.882,1.755,1.755,0,0,0,.891,1.033h0a1.621,1.621,0,0,0,.69.151Zm1.474-5.345a.641.641,0,0,1,.3.062.846.846,0,0,1,.419.521,3.065,3.065,0,0,1-.276,2.2c-.615,1.274-1.661,1.866-2.227,1.6a.846.846,0,0,1-.419-.521,3.064,3.064,0,0,1,.276-2.2A2.624,2.624,0,0,1,51.22,27.889Z"
                              transform="translate(-42.772 -20.762)"
                              fill="#707070"
                            />
                            <path
                              id="Контур_10121"
                              data-name="Контур 10121"
                              d="M46.495,34.332a3.4,3.4,0,0,0,2.713-2.174c.788-1.639.552-3.354-.535-3.9l-1.782-.891-.4.8,1.782.891c.561.276.748,1.434.134,2.713s-1.661,1.866-2.227,1.6L44.4,32.479l-.4.8,1.822.891a1.55,1.55,0,0,0,.668.165Z"
                              transform="translate(-37.854 -20.969)"
                              fill="#707070"
                            />
                            <rect
                              id="Прямоугольник_2308"
                              data-name="Прямоугольник 2308"
                              width="0.7"
                              height="0.7"
                              transform="translate(9.207 3.5)"
                              fill="#707070"
                            />
                          </svg>
                        </span>
                        הקופה שלי
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}

                  <li className="menu__item">
                    <NavLink
                      to="/terms"
                      className="menu__link"
                    >
                      תנאי שימוש
                    </NavLink>
                  </li>
                  <li className="menu__item">
                    <NavLink
                      to="/contact-us"
                      className="menu__link"
                    >
                      <span className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="27"
                          height="26"
                          viewBox="0 0 27 26"
                        >
                          <g
                            id="Сгруппировать_1499"
                            data-name="Сгруппировать 1499"
                            transform="translate(1177 -2115)"
                          >
                            <g
                              id="Эллипс_411"
                              data-name="Эллипс 411"
                              transform="translate(-1177 2115)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1.5"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                stroke="none"
                              />
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="3.75"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Эллипс_412"
                              data-name="Эллипс 412"
                              transform="translate(-1159 2122)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1.5"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                stroke="none"
                              />
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="3.75"
                                fill="none"
                              />
                            </g>
                            <g
                              id="Эллипс_413"
                              data-name="Эллипс 413"
                              transform="translate(-1175 2132)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1.5"
                            >
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="4.5"
                                stroke="none"
                              />
                              <circle
                                cx="4.5"
                                cy="4.5"
                                r="3.75"
                                fill="none"
                              />
                            </g>
                            <line
                              id="Линия_524"
                              data-name="Линия 524"
                              x2="10"
                              y2="4"
                              transform="translate(-1168.5 2120.5)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1.5"
                            />
                            <line
                              id="Линия_525"
                              data-name="Линия 525"
                              y1="6"
                              x2="9"
                              transform="translate(-1166.5 2128.5)"
                              fill="none"
                              stroke="#707070"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </span>
                      צור קשר
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="header-mobile header-mobile">
        <div className="header-mobile__container">
          <div className="header-mobile__body">
            <div className="header-mobile__burger-container">
              <button
                type="button"
                onClick={burgerToggle}
                className="header-mobile__burger icon-menu"
              >
                <span></span>
              </button>
            </div>

            <div className="header-mobile__other-actions">
              {/* <button
                onClick={() => goToReminders()}
                className="header-mobile__notifications"
                data-count="2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32.673"
                  height="37.078"
                  viewBox="0 0 32.673 37.078"
                >
                  <g
                    id="Компонент_493_12"
                    data-name="Компонент 493 – 12"
                    transform="translate(0.523 0.539)"
                  >
                    <path
                      id="Контур_39385"
                      data-name="Контур 39385"
                      d="M38.729,34.345a1.8,1.8,0,0,0-.812-.88c-4.5-2.267-3.6-3.909-4.311-11.258-.646-6.508-2.541-11.14-8.039-12.137a.267.267,0,0,1-.194-.154A.241.241,0,0,1,25.4,9.68a2.821,2.821,0,0,0,.541-1.759,2.623,2.623,0,0,0-1.408-2.449,2.991,2.991,0,0,0-2.964.072A2.6,2.6,0,0,0,20.3,8.057a2.733,2.733,0,0,0,.541,1.622.241.241,0,0,1,.035.231.264.264,0,0,1-.18.16c-5.519,1-7.414,5.629-8.06,12.137-.708,7.349.187,8.991-4.311,11.258a1.773,1.773,0,0,0-.791.88,2.258,2.258,0,0,0,.178,2.232,2.579,2.579,0,0,0,2.071,1.11H36.459a2.581,2.581,0,0,0,2.086-1.1A2.258,2.258,0,0,0,38.729,34.345Z"
                      transform="translate(-7.317 -5.114)"
                      fill="#fff"
                      stroke="#707070"
                      strokeWidth="1"
                    />
                    <path
                      id="Контур_39386"
                      data-name="Контур 39386"
                      d="M18.807,22.45a2.924,2.924,0,0,1-3.182,2.577A2.937,2.937,0,0,1,12.43,22.45Z"
                      transform="translate(0.183 10.986)"
                      fill="#fff"
                      stroke="#707070"
                      strokeWidth="1"
                    />
                  </g>
                </svg>
              </button>*/}
              <button
                className="header-mobile__prev"
                onClick={handleGoBack}
              >
                <img
                  src={arrowLeftWhite}
                  alt="arrow left white"
                />
              </button>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <div className="header-mobile__menu mobile-menu mobile-menu_logged">
            <div className="mobile-menu__body">
              <div className="mobile-menu__logo">
                <img
                  src={logoImg}
                  alt="logo"
                />
              </div>
              <div className="mobile-menu__user">
                <div className="mobile-menu__user-icon">
                  <img
                    src={userIcon}
                    alt="user icon"
                  />
                </div>
                <p className="mobile-menu__user-name">
                  שלום: <span>{userName}</span>
                </p>
                <div className="mobile-menu__user-actions">
                  <NavLink
                    to="/edit-profile"
                    className="mobile-menu__btn edit"
                    onClick={burgerToggle}
                  >
                    <svg
                      fill="#3C9E1C"
                      viewBox="0 0 32 32"
                      style={{
                        fillRule: "evenodd",
                        clipRule: "evenodd",
                        strokeLinejoin: "round",
                        strokeMiterlimit: 2,
                      }}
                    >
                      <g
                        id="SVGRepo_bgCarrier"
                        strokeWidth="0"
                      ></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="M18.257,6.671l-12.664,12.664c-0.119,0.119 -0.206,0.266 -0.253,0.428l-2.3,7.899c-0.102,0.349 -0.006,0.725 0.25,0.984c0.256,0.258 0.631,0.357 0.981,0.258l7.957,-2.242c0.165,-0.047 0.315,-0.135 0.436,-0.256l12.664,-12.664l-7.071,-7.071Zm1.414,-1.414l7.071,7.071l1.793,-1.792c0.938,-0.938 1.465,-2.21 1.465,-3.536c-0,-1.326 -0.527,-2.598 -1.465,-3.536c0,0 0,0 0,0c-0.938,-0.937 -2.209,-1.464 -3.535,-1.464c-1.327,0 -2.598,0.527 -3.536,1.464l-1.793,1.793Z"></path>
                        <g id="Icon"></g>
                      </g>
                    </svg>
                  </NavLink>

                  <a
                    onClick={logout}
                    href="#"
                    className="mobile-menu__btn logout"
                  >
                    <svg
                      width="64px"
                      height="64px"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="SVGRepo_bgCarrier"
                        strokeWidth="0"
                      ></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <rect
                          width="48"
                          height="48"
                          fill="white"
                          fillOpacity="0.01"
                        ></rect>
                        <path
                          d="M23.9917 6L6 6L6 42H24"
                          stroke="#3C9E1C"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M25 33L16 24L25 15"
                          stroke="#3C9E1C"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M42 23.9917L16 23.9917"
                          stroke="#3C9E1C"
                          strokeWidth="4"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>

              <nav className="mobile-menu__navigation">
                <div
                  data-spollers
                  className="mobile-menu__spollers"
                >
                  {navigationData.map((menuItem, index) => (
                    <div
                      key={index}
                      className="mobile-menu__spollers-item"
                      open={menuItem.text === activeLink}
                    >
                      {menuItem.submenu ? (
                        <details className="mobile-menu__spollers-item">
                          <summary className={`mobile-menu__spollers-title`}>
                            {menuItem.text}
                          </summary>
                          <div className="mobile-menu__spollers-body">
                            {menuItem.submenu.map((submenuItem, subIndex) => (
                              <NavLink
                                key={subIndex}
                                to={submenuItem.url}
                                className={`mobile-menu__spollers-title`}
                                onClick={() => handleLinkClick(submenuItem)}
                              >
                                {submenuItem.text}
                              </NavLink>
                            ))}
                          </div>
                        </details>
                      ) : (
                        <NavLink
                          to={menuItem.url}
                          onClick={() => handleLinkClick(menuItem)}
                          className="mobile-menu__spollers-title stop-icon-rotate"
                        >
                          {menuItem.text}
                        </NavLink>
                      )}
                    </div>
                  ))}
                </div>
              </nav>

              <div className="mobile-menu__socials">
                <ul className="mobile-menu__socials-list">
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                      />
                    </a>
                  </li>
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
        {!isLoggedIn && (
          <div className="header-mobile__menu mobile-menu mobile-menu_not-logged">
            <div className="mobile-menu__body">
              <div className="mobile-menu__logo">
                <img
                  src={logoImg}
                  alt="logo"
                />
              </div>
       
              <nav className="mobile-menu__navigation">
                <div
                  data-spollers
                  className="mobile-menu__spollers"
                >
                  {navigationDataNotLogged.map((menuItem, index) => (
                    <div
                      key={index}
                      className="mobile-menu__spollers-item"
                      open={menuItem.text === activeLink}
                    >
                      {menuItem.submenu ? (
                        <details className="mobile-menu__spollers-item">
                          <summary className={`mobile-menu__spollers-title`}>
                            {menuItem.text}
                          </summary>
                          <div className="mobile-menu__spollers-body">
                            {menuItem.submenu.map((submenuItem, subIndex) => (
                              <NavLink
                                key={subIndex}
                                to={submenuItem.url}
                                className={`mobile-menu__spollers-title`}
                                onClick={() => handleLinkClick(submenuItem)}
                              >
                                {submenuItem.text}
                              </NavLink>
                            ))}
                          </div>
                        </details>
                      ) : (
                        <NavLink
                          to={menuItem.url}
                          onClick={() => handleLinkClick(menuItem)}
                          className="mobile-menu__spollers-title stop-icon-rotate"
                        >
                          {menuItem.text}
                        </NavLink>
                      )}
                    </div>
                  ))}
                </div>
              </nav>
              <div className="mobile-menu__socials">
                <ul className="mobile-menu__socials-list">
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                      />
                    </a>
                  </li>
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li className="mobile-menu__socials-item">
                    <a href="#">
                      <img
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
